/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import React, { FC, useState } from 'react'
import { Input, Button } from 'antd'
import { ContactSectionInterface } from './interfaces'
import Spinner from '../../core-ui/Spinner'
import { emailRegex } from '../../utils/regex'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
// export function phoneFormatter(phone: string) {
//   let str = phone
//   if (phone.length == 1) str = `(${str}`
//   if (phone.length == 4) str = `${str}) `
//   if (phone.length == 9) str = `${str}-`
//   if (phone.length > 14) str = str.slice(0, 14)
//   return str
// }

export const phoneFormatter = (input: string) => {
  const numbers = input.replace(/\D/g, '') // Remove non-numeric characters

  // Check if the input is not empty
  if (numbers === '') {
    // If input is empty, reset the phone number state
    return ''
  }

  // Check if the input does not start with 1

  // Format the phone number
  let formattedNumber = numbers.replace(/(\d{1,3})(\d{1,3})?(\d{1,4})?/, (match, p1, p2, p3) => {
    const part1 = p1 ? '(' + p1 : ''
    const part2 = p2 ? ') ' + p2 : ''
    const part3 = p3 ? '-' + p3 : ''
    return part1 + part2 + part3
  })

  // Limit the length of formattedNumber to 14 characters
  formattedNumber = formattedNumber.slice(0, 14)

  // Check if backspacing and adjust formatting accordingly
  if (input.length < formattedNumber.length) {
    if (input.charAt(input.length - 1) === ' ') {
      formattedNumber = formattedNumber.slice(0, -2) // Remove space and previous character
    } else if (input.charAt(input.length - 1) === ')') {
      formattedNumber = formattedNumber.slice(0, -2) // Remove closing parenthesis and previous character
    } else if (input.charAt(input.length - 1) === '-') {
      formattedNumber = formattedNumber.slice(0, -1) // Remove hyphen
    }
  }

  // Update the input field value
  return formattedNumber
}

const ContactSection: FC<ContactSectionInterface> = ({
  contactAction,
  contactStatus,
  errorText,
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { TextArea } = Input
  const [buttonClick, setButtonClick] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    city: '',
    phoneNumber: '',
    email: '',
    message: '',
  })

  const handleChange = (
    event_: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    stateName: string,
  ) => {
    let { value } = event_.target
    if (stateName === 'phoneNumber') {
      value = phoneFormatter(value)
      console.log(value)
    }
    if (stateName === 'email' && !emailRegex.test(value.trim())) {
      setEmailError(true)
    } else if (emailRegex.test(value.trim())) {
      setEmailError(false)
    }
    setFormData((previousState) => ({ ...previousState, [stateName]: value }))
  }

  const handleClick = async () => {
    if (!executeRecaptcha) {
      return
    }
    const token = await executeRecaptcha('your_action')
    setButtonClick(true)
    if (
      formData.firstName &&
      formData.lastName &&
      formData.email &&
      formData.phoneNumber &&
      formData.message !== ''
    ) {
      const body = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phoneNumber,
        message: formData.message,
        recaptcha_token: token,
      }
      contactAction(body)
    }
  }

  return (
    <>
      <div className='container'>
        <div className='contact-us-section'>
          <div className='title-box'>
            <h1>Contact Us</h1>
            <p>We’d love to hear from you. Please fill out this form.</p>
          </div>
          <div className='fields-section'>
            <div className='fields'>
              <div className='colum'>
                <div className='child-box'>
                  <label>First name*</label>
                  <Input
                    className='input-box'
                    onChange={(e) => handleChange(e, 'firstName')}
                    placeholder='First name'
                  />
                  {formData.firstName === '' && buttonClick ? (
                    <p className='error-message message-active'>*Please enter a valid firstName</p>
                  ) : (
                    <p className='error-message message-disable'>*Please enter a valid firstName</p>
                  )}
                </div>
                <div className='child-box'>
                  <label>Last name*</label>
                  <Input
                    className='input-box'
                    onChange={(e) => handleChange(e, 'lastName')}
                    placeholder='Last name'
                  />
                  {formData.lastName === '' && buttonClick ? (
                    <p className='error-message message-active'>*Please enter a valid lastName</p>
                  ) : (
                    <p className='error-message message-disable'>*Please enter a valid lastName</p>
                  )}
                </div>
              </div>
              <div className='colum'>
                <div className='child-box'>
                  <label>Email Address* </label>
                  <Input
                    className='input-box'
                    onChange={(e) => handleChange(e, 'email')}
                    placeholder='Enter your email'
                  />
                  {(formData.email === '' || emailError) && buttonClick ? (
                    <p className='error-message message-active'>
                      *Please enter a valid email address
                    </p>
                  ) : (
                    <p className='error-message message-disable'>
                      *Please enter a valid email address
                    </p>
                  )}
                </div>
                <div className='child-box'>
                  <label>Phone number*</label>
                  <Input
                    onChange={(e) => handleChange(e, 'phoneNumber')}
                    type='text'
                    value={formData.phoneNumber}
                    className='input-box'
                    placeholder='Phone number'
                  />
                  {formData.phoneNumber === '' && buttonClick ? (
                    <p className='error-message message-active'>
                      *Please enter a valid phoneNumber
                    </p>
                  ) : (
                    <p className='error-message message-disable'>
                      *Please enter a valid phoneNumber
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className='textarea-box'>
              <div className='textarea-section'>
                <label>Message*</label>
                <TextArea
                  onChange={(e) => handleChange(e, 'message')}
                  rows={4}
                  placeholder='maxLength is 500'
                  maxLength={500}
                />
                {formData.message === '' && buttonClick ? (
                  <p className='error-message message-active'>*Please enter a valid Message</p>
                ) : (
                  <p className='error-message message-disable'>*Please enter a valid Message</p>
                )}
              </div>
            </div>
            <div className='error-section'>
              {errorText && buttonClick && contactStatus !== 'pending' ? (
                <p className='error-message message-active'>{errorText}</p>
              ) : (
                <p className='error-message message-disable'>{errorText}</p>
              )}
            </div>
            <div className='button-box'>
              <Button onClick={handleClick}>
                {contactStatus === 'pending' ? <Spinner /> : 'Send message'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactSection
