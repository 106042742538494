import React, { useState, FC, useEffect } from 'react'
import './styles.scss'
import { connect, useDispatch, useSelector } from 'react-redux'
import { CombineInterface } from '../../../store/reducers/interfaces'
import { SignUpInterfaces } from './interfaces'
import {
  employSignup,
  ResendSignUp,
  signUp,
  signUpReset,
  verifyPhone,
} from '../../../store/actions/AuthActions'
import {
  EmploySignup,
  SignUpInterface,
  VerifyPhoneInterface,
} from '../../../store/actions/interfaces'
import { emailRegex, passwordRegex } from '../../../utils/regex'
import EmployLogin from './SignUpFields'
import InnerPage from '../../InnerPage'
import { useLocation, useNavigate } from 'react-router'

const SignUp: FC<SignUpInterfaces> = ({
  authReducer: { signUpData, verifyData, signUpStatus, verifyStatus, signInStatus },
  signUpAction,
  verifyPhoneAction,
  signUpResetAction,
  resendSignUp,
}) => {
  const { profileInfoNoPendingData } = useSelector((state: any) => state.profileReducer)
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const location = useLocation()
  const body = {
    email: '',
    id: '',
    password: '',
  }

  body.email = location.search.split('=')[1].split('&')[0]
  body.id = location.search.split('=')[2]

  const [checkBox, setCheckBox] = useState(false)
  const [errorBackState, setErrorBackState] = useState(false)
  const [formData, setFormData] = useState([
    {
      name: 'Password*',
      placeholder: 'Enter your password',
      key: 'Password',
      type: 'Password',
      value: '',
      label: 'Password*',
      errorText: '*Please enter a valid password',
      errorVisible: false,
    },
    {
      name: 'Confirm*',
      placeholder: 'Confirm password',
      key: 'Confirm',
      value: '',
      type: 'Confirm',
      label: 'Confirm*',
      errorText: '*Passwords must be the same.',
      errorVisible: false,
    },
  ])

  const [buttonClick, setButtonClick] = useState(false)
  let isPasswordSame = false

  const formValidate = (isPasswordSame: boolean) => {
    const newData = [...formData]
    let isValid = true
    console.log(isPasswordSame)
    for (const [idx, { key, value }] of formData.entries()) {
      if (key === 'Password' && !passwordRegex.test(value.trim())) {
        newData[idx].errorVisible = true
        isValid = false
      }
      if (key === 'Confirm' && !isPasswordSame) {
        newData[idx].errorVisible = true
        isValid = false
      }
    }

    setFormData(newData)

    return isValid
  }
  const formDataFunc = () => {
    const result: Record<string, string> = {}
    for (const { key, value } of formData) {
      result[key] = value
    }
    return result
  }
  const handleClickButton = () => {
    setButtonClick(true)
    isPasswordSame = (formData[0].value || {}) == (formData[1].value || {})

    const validity = formValidate(isPasswordSame)
    if (validity) {
      setErrorBackState(false)
      const { Password } = formDataFunc()
      body.password = Password

      dispatch(employSignup(body as any) as any)
    }
  }

  useEffect(() => {
    if (signUpStatus === 'success') {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }, [checkBox, signUpStatus])
  useEffect(() => {
    if (profileInfoNoPendingData?.data?.profileId) return navigate('/')
  }, [profileInfoNoPendingData?.data?.profileId])

  return (
    <>
      <InnerPage>
        <div className='sign-up-section'>
          <div className='sign-up-box'>
            <EmployLogin
              formData={formData}
              handleClickButton={handleClickButton}
              setFormData={setFormData}
              signUpStatus={signUpStatus}
              signInStatus={signInStatus}
              errorMessage={signUpData?.message || ''}
              errorBackState={errorBackState}
              setErrorBackState={setErrorBackState}
            />
          </div>
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  signUpAction: (body: SignUpInterface) => dispatch(signUp(body)),
  verifyPhoneAction: (body: VerifyPhoneInterface) => dispatch(verifyPhone(body)),
  signUpResetAction: () => dispatch(signUpReset()),
  resendSignUp: (username: string) => dispatch(ResendSignUp(username)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(SignUp)
