import React, { useEffect, useState } from 'react'
import './style.scss'
import InnerPage from '../../InnerPage'
import LandingPageForm from './LandingPageForm'
import WebsitePreview from './WebsitePreview'
import { useDispatch, useSelector } from 'react-redux'
import { LandingPageBody } from '../../../store/actions/interfaces'
import { createThroughPageGenerator } from '../../../store/actions/BusinessAction'

const index = () => {
  const { success } = useSelector((state: any) => state.BusinessReducer)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState<LandingPageBody>({
    companyName: '',
    websiteUrl: '',
    pocName: '',
    pocEmailAddress: '',
    pocTitle: '',
    pocPhone: '',
    estimateUsers: 0,
    logo: '',
    buttonBackgroundColor: '#2ba1db',
    buttonTextColor: '#ffffff',
  })

  const handleChange = (e: any) => {
    const { name, value, files } = e.target
    if (name === 'logo') {
      setFormData({ ...formData, [name]: files[0] })
    } else {
      setFormData({ ...formData, [name]: value })
    }
  }
  const handleSubmit = (event: any) => {
    event.preventDefault()
    dispatch(createThroughPageGenerator(formData) as any)
  }

  useEffect(() => {
    if (success == 'Company profile created successfully.') {
      setFormData({
        companyName: '',
        websiteUrl: '',
        pocName: '',
        pocEmailAddress: '',
        pocTitle: '',
        pocPhone: '',
        estimateUsers: 0,
        logo: '',
        buttonBackgroundColor: '#2ba1db',
        buttonTextColor: '#ffffff',
      })
    }
  }, [success])

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <InnerPage>
          <div className='d-flex justify-content-center'>
            <div className='landing-page-container'>
              <div className='landing-page-form'>
                <h1 className=''>Landing Page Generator</h1>
                <p>Fill all details about your Company</p>
                <LandingPageForm
                  formData={formData}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              </div>
              <div className='landing-page-website'>
                <WebsitePreview formData={formData} />
              </div>
            </div>
          </div>
        </InnerPage>
      </form>
    </div>
  )
}

export default index
