import React from 'react'
import CTABox from './CTABox'
import Api from '../../utils/Api'
import Benefit from '../../utils/Benifit'

export default function CallToAction() {
  const headingOne = 'Employee Benefits'
  const headingTwo = 'API Integration'
  const paraOne =
    'This one’s a no-brainer. Hackfree is a deep, multi-pronged suite of services that keeps your team safe while boosting their personal and  professional online presence.'
  const paraTwo = 'Add value to your existing product for customers or employees.'
  return (
    <div className='call-to-action'>
      <div className='container-fluid'>
        <h1>How to get Hackfree</h1>
        <div className='cta-container'>
          <CTABox heading={headingOne} paragraph={paraOne} SvgImage={<Benefit />} />
          <CTABox heading={headingTwo} paragraph={paraTwo} SvgImage={<Api />} />
        </div>
      </div>
    </div>
  )
}
