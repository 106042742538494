// reducers/formDataReducer.js
const initialState = {
  companyName: '',
  websiteURL: '',
  poc: '',
  pocEmail: '',
  pocTitle: '',
  pocPhone: '',
  estimatedUsers: '',
  logo: null,
  buttonBgColor: '',
  buttonTextColor: '',
}

const formDataReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATE_FORM_DATA':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default formDataReducer
