import { FC } from 'react'
import { Input } from './interface'
export const InputField: FC<Input> = ({ title, placeholder }) => {
  return (
    <>
      <div className='input-field d-flex flex-column'>
        <label>{title}</label>
        <input className='bg-transparent' type='text' name='' id='' placeholder={placeholder} />
      </div>
    </>
  )
}
