import { BusinessReducer } from './BusinessReducer'
import { LogoReducer } from './LogoReducer'
import { combineReducers } from 'redux'

import subscribeReducer from './SubscribeReducers'
import formDataReducer from './formDataReducer'
import contactReducer from './ContactReducers'
import profileReducer from './ProfileReducers'
import searchReducer from './SearchReducers'
import resultReducer from './ResultReducers'
import authReducer from './AuthReducers'
import { fieldType } from '../actions/ProfileAction'

export type DispatchPayloadType = {
  type: string
  payload?: any
  error?: any
  deleteField?: {
    id: number
    fieldName: fieldType
  }
}

const rootReducer = combineReducers({
  authReducer,
  searchReducer,
  resultReducer,
  subscribeReducer,
  contactReducer,
  profileReducer,
  formDataReducer,
  BusinessReducer,
  LogoReducer,
})

export default rootReducer
