import React, { FC } from 'react'
import './styles.scss'
import InnerPage from '../InnerPage'
import PrivacyPoliceSection from './PrivacyPolicy'

const PrivacyPolice: FC = () => {
  return (
    <>
      <InnerPage>
        <PrivacyPoliceSection />
      </InnerPage>
    </>
  )
}

export default PrivacyPolice
