import { Button } from 'antd'

export default function InfoSection() {
  return (
    <div className='section'>
      <div className='d-grid gap-3 grid-columns'>
        <div className='hero-head'>
          <img
            src={require('../../assets/images/landingPage/google.svg').default}
            alt='hero image'
          />
        </div>
        <div className='hero-head'>
          <h1 className='heading-info'>
            Did you <span>know...</span>
          </h1>
          <p>Just a quick Google search can uncover a wealth of information about you.</p>
          <p>
            It&apos;s a prevalent issue – people-search websites gather personal data from public
            records, retail transactions, and other sources, then sell it for profit.
          </p>
          <Button>Grab Deal</Button>
        </div>
      </div>
    </div>
  )
}
