export default {
  // Auth: {
  //   region: process.env.REACT_APP_REGION,
  //   userPoolId: process.env.REACT_APP_USERPOOL_ID,
  //   userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
  //   oauth: {
  //     domain: process.env.REACT_APP_DOMAIN,
  //     clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //     redirectSignIn: 'https://dhtyx3t3s1cx7.cloudfront.net/',
  //     redirectSignOut: 'https://dhtyx3t3s1cx7.cloudfront.net/',
  //     responseType: 'code',
  //   },
  // },
  Auth: {
    identityPoolId: process.env.REACT_APP_GOOGLE_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USERPOOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    federationTarget: 'COGNITO_USER_POOLS',
  },
}
