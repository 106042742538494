import moment from 'moment'
import {
  PROFILE,
  PROFILE_INFO_NO_PENDING,
  PROFILE_RESULTS_NO_PENDING,
  PROFILE_EDIT,
  DELETE_ACCOUNT,
  CANCEL_SUBSCRIPTION,
  RESUME_SUBSCRIPTION,
  SUB_PROFILE,
  RESULT_REMOVE,
} from '../constants'
import { DispatchPayloadType } from './index'

import { ProfileReducersInterface, subProfileData } from './interfaces'

const initialState: ProfileReducersInterface = {
  profileStatus: '',
  profileData: null,
  subProfileStatus: '',
  subProfileData: {
    data: [
      {
        address: '',
        age: 0,
        birthDate: '',
        city: '',
        email: '',
        enrollmentId: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phoneNumber: '',
        profileId: '',
        profileType: '',
        state: '',
      },
    ],
    msg: '',
    code: 0,
  },

  profileInfoNoPendingStatus: '',
  profileInfoNoPendingData: null,

  profileResultNoPendingStatus: '',
  profileResultNoPendingData: null,

  profileEditStatus: '',
  profileEditData: null,

  accountDeleteStatus: '',
  accountDeleteData: null,

  cancelSubscriptionStatus: '',
  cancelSubscriptionData: null,

  resumeSubscriptionStatus: '',
  resumeSubscriptionData: null,
}

const profileReducer = (
  state = initialState,
  { type, payload, error, deleteField }: DispatchPayloadType,
): ProfileReducersInterface => {
  switch (type) {
    case PROFILE.PENDING: {
      return {
        ...state,
        profileStatus: 'pending',
      } as ProfileReducersInterface
    }
    case PROFILE.SUCCESS: {
      return {
        ...state,
        profileStatus: 'success',
        profileData: payload,
      } as ProfileReducersInterface
    }
    case PROFILE.ERROR: {
      return {
        ...state,
        profileStatus: 'error',
        profileData: error?.response?.data,
      } as ProfileReducersInterface
    }
    case PROFILE.RESET: {
      return {
        ...state,
        profileStatus: '',
      } as ProfileReducersInterface
    }

    // THIS IS FOR SUB PROFILE
    case SUB_PROFILE.PENDING: {
      return {
        ...state,
        subProfileStatus: 'pending',
      } as ProfileReducersInterface
    }
    case SUB_PROFILE.SUCCESS: {
      return {
        ...state,
        subProfileStatus: 'success',
        subProfileData: payload,
      } as ProfileReducersInterface
    }
    case SUB_PROFILE.DELETE: {
      return {
        ...state,
        subProfileStatus: 'success',
        subProfileData: Array.isArray(state.subProfileData?.data)
          ? state.subProfileData?.data?.filter(
              (item: subProfileData) => item.profileId !== payload.id,
            )
          : payload,
      } as ProfileReducersInterface
    }
    case SUB_PROFILE.ERROR: {
      return {
        ...state,
        subProfileStatus: 'error',
        subProfileData: error?.response?.data,
      } as ProfileReducersInterface
    }
    case SUB_PROFILE.RESET: {
      return {
        ...state,
        subProfileStatus: '',
      } as ProfileReducersInterface
    }

    case PROFILE_INFO_NO_PENDING.PENDING: {
      return {
        ...state,
        profileInfoNoPendingStatus: 'pending',
      } as ProfileReducersInterface
    }
    case PROFILE_INFO_NO_PENDING.SUCCESS: {
      return {
        ...state,
        profileInfoNoPendingStatus: 'success',
        profileInfoNoPendingData: payload,
      } as ProfileReducersInterface
    }
    case PROFILE_INFO_NO_PENDING.ERROR: {
      return {
        ...state,
        profileInfoNoPendingStatus: 'error',
        profileInfoNoPendingData: error.response?.data,
      } as ProfileReducersInterface
    }
    case PROFILE_INFO_NO_PENDING.RESET: {
      return {
        ...state,
        profileStatus: '',
      } as ProfileReducersInterface
    }

    case PROFILE_RESULTS_NO_PENDING.PENDING: {
      return {
        ...state,
        profileResultNoPendingStatus: 'pending',
      } as ProfileReducersInterface
    }
    case PROFILE_RESULTS_NO_PENDING.SUCCESS: {
      return {
        ...state,
        profileResultNoPendingStatus: 'success',
        profileResultNoPendingData: payload,
      } as ProfileReducersInterface
    }
    case PROFILE_RESULTS_NO_PENDING.ERROR: {
      return {
        ...state,
        profileResultNoPendingStatus: 'error',
        profileResultNoPendingData: error?.response?.data,
      } as ProfileReducersInterface
    }
    case PROFILE_RESULTS_NO_PENDING.RESET: {
      return {
        ...state,
        profileResultNoPendingStatus: '',
      } as ProfileReducersInterface
    }

    case PROFILE_EDIT.PENDING: {
      return {
        ...state,
        profileEditStatus: 'pending',
      } as ProfileReducersInterface
    }

    case PROFILE_EDIT.DEL_PENDING: {
      return {
        ...state,
        profileEditStatus: 'LOADING',
      } as ProfileReducersInterface
    }
    case PROFILE_EDIT.SUCCESS: {
      return {
        ...state,
        profileEditStatus: 'success',
        profileEditData: payload,
      } as ProfileReducersInterface
    }
    case PROFILE_EDIT.DELETE_ONE: {
      return {
        ...state,
        profileEditStatus: 'good',
        profileInfoNoPendingData: deleteField && {
          ...state.profileInfoNoPendingData,
          data: {
            ...state.profileInfoNoPendingData?.data,
            [deleteField.fieldName]: state.profileInfoNoPendingData?.data[
              deleteField.fieldName
            ]?.filter((item) => item.id !== deleteField.id),
          },
        },
      } as ProfileReducersInterface
    }
    case PROFILE_EDIT.ERROR: {
      return {
        ...state,
        profileEditStatus: 'error',
        profileEditData: error?.response?.data,
      } as ProfileReducersInterface
    }
    case PROFILE_EDIT.RESET: {
      return {
        ...state,
        profileEditStatus: '',
      } as ProfileReducersInterface
    }

    case DELETE_ACCOUNT.PENDING: {
      return {
        ...state,
        accountDeleteStatus: 'pending',
      } as ProfileReducersInterface
    }
    case DELETE_ACCOUNT.SUCCESS: {
      return {
        ...state,
        accountDeleteStatus: 'success',
        accountDeleteData: payload,
      } as ProfileReducersInterface
    }
    case DELETE_ACCOUNT.ERROR: {
      return {
        ...state,
        accountDeleteStatus: 'error',
        accountDeleteData: error?.response?.data,
      } as ProfileReducersInterface
    }
    case DELETE_ACCOUNT.RESET: {
      return {
        ...state,
        accountDeleteStatus: '',
      } as ProfileReducersInterface
    }

    case CANCEL_SUBSCRIPTION.PENDING: {
      return {
        ...state,
        cancelSubscriptionStatus: 'pending',
      } as ProfileReducersInterface
    }
    case CANCEL_SUBSCRIPTION.SUCCESS: {
      return {
        ...state,
        cancelSubscriptionStatus: 'success',
        cancelSubscriptionData: payload,
      } as ProfileReducersInterface
    }
    case CANCEL_SUBSCRIPTION.ERROR: {
      return {
        ...state,
        cancelSubscriptionStatus: 'error',
        cancelSubscriptionData: error?.response?.data,
      } as ProfileReducersInterface
    }
    case CANCEL_SUBSCRIPTION.RESET: {
      return {
        ...state,
        cancelSubscriptionStatus: '',
      } as ProfileReducersInterface
    }

    case RESUME_SUBSCRIPTION.PENDING: {
      return {
        ...state,
        resumeSubscriptionStatus: 'pending',
      } as ProfileReducersInterface
    }
    case RESUME_SUBSCRIPTION.SUCCESS: {
      return {
        ...state,
        resumeSubscriptionStatus: 'success',
        resumeSubscriptionData: payload,
      } as ProfileReducersInterface
    }
    case RESUME_SUBSCRIPTION.ERROR: {
      return {
        ...state,
        resumeSubscriptionStatus: 'error',
        resumeSubscriptionData: error?.response?.data,
      } as ProfileReducersInterface
    }
    case RESUME_SUBSCRIPTION.RESET: {
      return {
        ...state,
        resumeSubscriptionStatus: '',
        resumeSubscriptionData: null,
      } as ProfileReducersInterface
    }

    case RESULT_REMOVE.SUCCESS: {
      const currentDate = moment().format('YYYY-MM-DD')
      state.profileResultNoPendingData?.data.result.map((item) => {
        const index = item.data[payload.website].details.findIndex(
          (item) => item.url == payload.profileUrl,
        )
        return item.data[payload.website].details.splice(index, 1, {
          ...item.data[payload.website].details[index],
          removalStatus: 'pending',
          removalRequestedOn: currentDate,
        })
      })
      return {
        ...state,
        profileResultNoPendingData: state.profileResultNoPendingData,
      } as any
    }

    case RESULT_REMOVE.ERROR: {
      const currentDate = moment().format('YYYY-MM-DD')
      state.profileResultNoPendingData?.data.result.map((item) => {
        const index = item.data[payload.website].details.findIndex(
          (item) => item.url == payload.profileUrl,
        )
        return item.data[payload.website].details.splice(index, 1, {
          ...item.data[payload.website].details[index],
          removalStatus: 'pending',
          removalRequestedOn: currentDate,
        })
      })
      return {
        ...state,
        profileResultNoPendingData: state.profileResultNoPendingData,
      } as any
    }

    default:
      return state
  }
}

export default profileReducer
