import React, { FC, useEffect, useState } from 'react'
import { Input, Button } from 'antd'
import { SignInFieldsInterface } from './interfaces'
import Spinner from '../../../core-ui/Spinner'
import PasswordPopover from './PasswordPopover'
import { validatePassword } from '../../../utils/regex'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

let passwordCheckOpened = false

const EmployLogin: FC<SignInFieldsInterface> = ({
  handleClickButton,
  formData,
  setFormData,
  signUpStatus,
  errorMessage,
  setErrorBackState,
  errorBackState,
  signInStatus,
}) => {
  const [passwordState, setPasswordState] = useState(false)
  const [select, setSelect] = useState<any>([])
  const isLogin = Boolean(localStorage.getItem('login'))
  const navigate = useNavigate()

  const handleChange = (event: any, fieldKey: any) => {
    setFormData((e) => {
      const result = [...e]
      const key = result.findIndex(({ key }) => key === fieldKey)
      result[key].value =
        fieldKey === 'email'
          ? event.target.value.toLowerCase().replace(/\s/g, '')
          : event.target.value.replace(/\s/g, '')
      if (fieldKey === 'Password' && passwordState && validatePassword(event.target.value)) {
        setPasswordState(false)
      } else if (
        fieldKey === 'Password' &&
        !validatePassword(event.target.value) &&
        !passwordState &&
        passwordCheckOpened
      ) {
        setPasswordState(true)
      }
      result[key].errorVisible = false
      return result
    })
    setErrorBackState(true)
  }

  const handleFocusValidation = (val: string, key: string, value: string) => {
    if (key === 'Password') {
      if (val === 'focus') {
        passwordCheckOpened = true
        if (!validatePassword(value)) {
          setPasswordState(true)
        }
      } else {
        setPasswordState(false)
      }
    }
  }

  useEffect(() => {
    if (isLogin) {
      // toast.info('Please Logout First', { toastId: 'logout' })
      navigate('/search-results')
    }
  }, [isLogin])

  return (
    <>
      <h1>Set your password</h1>
      <div className='d-flex flex-column gap-3 pb-3'>
        {formData.map(({ label, key, placeholder, errorText, value, errorVisible }, index) => (
          <div className='form-field-item' key={key}>
            <label>{label}</label>
            <Input
              type={select.includes(index) ? 'text' : 'password'}
              value={value}
              onChange={(e) => handleChange(e, key)}
              placeholder={placeholder}
              onFocus={() => handleFocusValidation('focus', key, value)}
              onBlur={() => handleFocusValidation('blur', key, value)}
              addonAfter={
                select.includes(index) ? (
                  <EyeInvisibleOutlined
                    onClick={() =>
                      setSelect((prev: any[]) => prev.filter((item) => item !== index))
                    }
                  />
                ) : (
                  <EyeOutlined onClick={() => setSelect((prev: any[]) => [...prev, index])} />
                )
              }
            />
            {/* {label == 'Password*' && <p className='password-info'>Must be at least 8 characters</p>} */}
            {errorVisible ? <p className='error-message message-active'>{errorText}</p> : ''}
            {key === 'Password' && passwordState && <PasswordPopover password={value} />}
          </div>
        ))}
      </div>

      {errorMessage && signUpStatus !== 'pending' && !errorBackState ? (
        <p className='error-message message-active'>{errorMessage}</p>
      ) : (
        ''
      )}
      <Button onClick={handleClickButton}>
        {signUpStatus === 'pending' || signInStatus === 'pending' ? <Spinner /> : 'Get Started'}
      </Button>
      <p className='text-center availability-color'>Currently only available to US residents</p>
    </>
  )
}

export default EmployLogin
