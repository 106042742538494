import { useNavigate } from 'react-router-dom'
import Spinner from '../../../core-ui/Spinner'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

const WebsitePreview = ({ formData }: any) => {
  const navigate = useNavigate()
  const { businessStatus, businessData, success, error } = useSelector(
    (state: any) => state.BusinessReducer,
  )
  const { buttonBackgroundColor, buttonTextColor } = formData || {}
  const buttonStyle = {
    backgroundColor: buttonBackgroundColor,
    color: buttonTextColor,
  }
  const GenerateLandingPage: any = () => {
    if (businessData?.businessId) {
      navigate('/business/landing-page/' + businessData.businessId)
    } else {
      toast.info('Please Create a Company first!', { toastId: 'company' })
    }
  }
  return (
    <div>
      <div className='website-preview'>
        <div className='website-header'>
          <h4>Website Preview</h4>
          <button type='submit'>
            {businessStatus === 'pending' ? <Spinner /> : 'Generate Landing Page link'}
          </button>
        </div>
        <div className='website-preview-container'>
          <div className='image-content'>
            <div className='image'>
              {formData?.logo && <img src={URL.createObjectURL(formData?.logo)} alt='' />}
            </div>
            <div className='content'>
              <h5>
                Hackfree services for <span>{formData?.companyName}</span>
              </h5>
              <p>
                We &apos;re delighted to offer an exclusive rate to CyberGuy affiliates. Benefit
                from continuous monitoring and automated removal across <span>80 platforms</span>.
              </p>
            </div>
          </div>
          <div className='packages'>
            <div className='packages-price'>
              <div className='container'>
                <div className='packages-price-container'>
                  <div className='package-block'>
                    <div>
                      <div className='price'>
                        <h5>$72</h5>
                        <p>
                          <del>$100</del>
                        </p>
                      </div>
                    </div>
                    <div className='save-money'>
                      <p>save 25%</p>
                    </div>
                  </div>
                  <p>Person per year</p>
                </div>
                <div className='packages-price-container'>
                  <div className='package-block'>
                    <div>
                      <div className='price'>
                        <h5>$72</h5>
                        <p>
                          <del>$100</del>
                        </p>
                      </div>
                    </div>
                    <div className='save-money'>
                      <p>save 25%</p>
                    </div>
                  </div>
                  <p>Family per year(Covers up to 6 adults)</p>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <button onClick={GenerateLandingPage} style={buttonStyle}>
                    Continue
                  </button>
                </div>
              </div>
            </div>
            <div className='package-content'>
              <div className='content-block'>
                <h6>Hackfree Overview for CyberGuy</h6>
                <span onClick={GenerateLandingPage}>Click here to view</span>
              </div>
              <div className='content-block'>
                <h6>Your Account</h6>
                <p>
                  Please register using your professional email address to access the special rate.
                  Your subscription covers a full year with pre-payment, and you&apos;ll receive
                  notifications when it&apos;s time for auto-renewal.
                </p>
              </div>
              <div className='content-block'>
                <h6>We Work to Protect Your Privacy and Identity</h6>
                <p>
                  If you&apos;re not entirely satisfied, please reach out to us, and we&apos;ll
                  strive to find a solution that meets your needs.
                </p>
              </div>
              <div className='content-block'>
                <h6>Questions?</h6>
                <p>Contact CyberGuy.</p>
                {(formData?.pocName || formData?.pocEmailAddress) && (
                  <p>
                    {formData?.pocName}, <span>{formData?.pocEmailAddress}</span>
                  </p>
                )}
              </div>
              <div className='content-block'>
                <h6>Help with your account</h6>
                <p>Contact your Hackfree account manager:</p>
                <p>
                  <span>{formData?.websiteUrl}</span>
                </p>
              </div>
            </div>
          </div>
          {success && <span className='text-success'>*{success}</span>}
          {error && <span className='text-danger'>*{JSON.stringify(error)}</span>}
        </div>
      </div>
    </div>
  )
}

export default WebsitePreview
