import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CONTACT } from '../../store/constants'

const HubspotContactForm = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/embed/v2.js'
    document.body.appendChild(script)

    const handleScriptLoad = () => {
      dispatch({ type: CONTACT.PENDING }) // Dispatch action at the start of the request
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '46032588',
          formId: 'd7a48df3-f073-474d-a4b7-e070dadf6ce1',
          target: '#hubspot-form',
          onFormReady: () => dispatch({ type: CONTACT.SUCCESS }),
        })
      }
    }

    script.addEventListener('load', handleScriptLoad)
  }, [dispatch])

  return (
    <div>
      <div id='hubspot-form'></div>
    </div>
  )
}

export default HubspotContactForm
