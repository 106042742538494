import { PlanBoxInterface } from './interface'
import { Button } from 'antd'

export default function DealCard({ imageLink, frequency, planName }: PlanBoxInterface) {
  return (
    <div className='deal-card'>
      <div className='d-flex deal-card-gap'>
        <img src={imageLink} alt={planName} />
        <h2>{planName}</h2>
      </div>
      <div className='d-flex'>
        <h1>{planName == 'Starter' ? '$10' : '$20'}</h1>
        <div className='d-flex'>
          <h2 className='text-decoration-line-through'>{planName == 'Starter' ? '$15' : '$30'} </h2>
          <h2 className='plan-type'>/monthly</h2>
        </div>
      </div>
      <div className='bill-frequency'>billed {frequency}</div>
      <div className='allowed-people'>
        <img src={require('../../assets/images/landingPage/check.svg').default} alt='Check issue' />
        {planName == 'Starter' ? 'Just you' : 'Up to 6 people'}
      </div>
      <div className='button'>
        <Button>Get Started</Button>
      </div>
    </div>
  )
}
