import { FC } from 'react'
import '../styles.scss'
import { Button, Popover } from 'antd'
import { MobileHeaderInterface } from '../interfaces'
import { useNavigate } from 'react-router-dom'

const MobileHeader: FC<MobileHeaderInterface> = ({
  content,
  login,
  firstName,
  lastName,
  handleLogOutClick,
  payment,
}) => {
  const navigate = useNavigate()
  return (
    <>
      <div className='mobile-header'>
        <>
          <ul>
            <li
              className={window.location.pathname === '/' ? 'active' : ''}
              onClick={() => navigate('/')}
            >
              Home
            </li>
            <li
              className={window.location.pathname === '/contact' ? 'active' : ''}
              onClick={() => navigate('/contact')}
            >
              Contact
            </li>
            {/* {!payment ? (
              <li
                className={window.location.pathname === '/subscribe' ? 'active' : ''}
                onClick={() => navigate('/subscribe')}
              >
                Pricing
              </li>
            ) : (
              ''
            )} */}
            {login && (
              <li
                className={window.location.pathname === '/search-result' ? 'active' : ''}
                onClick={() => navigate('/search-results')}
              >
                Search Result
              </li>
            )}
            {login ? (
              <>
                <Popover placement='bottomLeft' content={content}>
                  <li style={{ paddingBottom: '10px' }}>
                    <img
                      src={require('../../../assets/images/header/avatar-login.svg').default}
                      style={{ height: '30px', width: '30px' }}
                    />
                    <span style={{ marginLeft: '5px' }}>
                      {' '}
                      {firstName ? firstName : ''} {lastName ? lastName : ''}
                    </span>
                  </li>
                </Popover>
              </>
            ) : (
              <div className='info-button'>
                <Button onClick={() => navigate('/sign-in')}>
                  Login <img src={require('../../../assets/images/header/avatar.svg').default} />
                </Button>
              </div>
            )}
            {/* {login ? (
              <>
                <li
                  className={window.location.pathname === '/subscribe' ? 'active' : ''}
                  onClick={() => handleLogOutClick()}
                >
                  View profile
                </li>
                <li
                  className={
                    window.location.pathname === '/subscribe'
                      ? 'active last-part'
                      : 'mb-3 last-part'
                  }
                  onClick={() => navigate('/subscribe')}
                >
                  Log out
                </li>
              </>
            ) : (
              ''
            )} */}
          </ul>
        </>
      </div>
    </>
  )
}

export default MobileHeader
