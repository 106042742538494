import React, { FC } from 'react'
import './styles.scss'
import { CircularProgress } from '@mui/material'
import { SpinnerInterface } from './interfaces'

const Spinner: FC<SpinnerInterface> = ({ size = 40, color = '#348ccb' }) => {
  return (
    <CircularProgress
      size={size}
      style={{
        alignContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
        display: 'block',
        color: color,
        zIndex: 1500,
      }}
    />
  )
}

export default Spinner
