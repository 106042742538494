import { SUBSCRIBE_PAYMENT, GET_SUBSCRIPTION, SUBSCRIPTION_UPGRADE } from '../constants'
import { DispatchPayloadType } from './index'

import { SubscribeReducersInterface } from './interfaces'

const initialState: SubscribeReducersInterface = {
  subscribeStatus: '',
  subscribeData: null,

  getSubscribeStatus: '',
  getSubscribeData: null,

  subscriptionUpgradeStatus: '',
  subscriptionUpgradeData: null,
}

const subscribeReducer = (
  state = initialState,
  { type, payload, error }: DispatchPayloadType,
): SubscribeReducersInterface => {
  switch (type) {
    case SUBSCRIBE_PAYMENT.PENDING: {
      return {
        ...state,
        subscribeStatus: 'pending',
      } as SubscribeReducersInterface
    }
    case SUBSCRIBE_PAYMENT.SUCCESS: {
      return {
        ...state,
        subscribeStatus: 'success',
        subscribeData: payload,
      } as SubscribeReducersInterface
    }
    case SUBSCRIBE_PAYMENT.ERROR: {
      return {
        ...state,
        subscribeStatus: 'error',
        subscribeData: error,
      } as SubscribeReducersInterface
    }
    case SUBSCRIBE_PAYMENT.RESET: {
      return {
        ...state,
        subscribeStatus: '',
      } as SubscribeReducersInterface
    }

    case GET_SUBSCRIPTION.PENDING: {
      return {
        ...state,
        getSubscribeStatus: 'pending',
      } as SubscribeReducersInterface
    }
    case GET_SUBSCRIPTION.SUCCESS: {
      return {
        ...state,
        getSubscribeStatus: 'success',
        getSubscribeData: payload,
      } as SubscribeReducersInterface
    }
    case GET_SUBSCRIPTION.ERROR: {
      return {
        ...state,
        getSubscribeStatus: 'error',
        getSubscribeData: error,
      } as SubscribeReducersInterface
    }
    case GET_SUBSCRIPTION.RESET: {
      return {
        ...state,
        getSubscribeStatus: '',
      } as SubscribeReducersInterface
    }

    case SUBSCRIPTION_UPGRADE.PENDING: {
      return {
        ...state,
        subscriptionUpgradeStatus: 'pending',
      } as SubscribeReducersInterface
    }
    case SUBSCRIPTION_UPGRADE.SUCCESS: {
      return {
        ...state,
        subscriptionUpgradeStatus: 'success',
        subscriptionUpgradeData: payload,
      } as SubscribeReducersInterface
    }
    case SUBSCRIPTION_UPGRADE.ERROR: {
      return {
        ...state,
        subscriptionUpgradeStatus: 'error',
        subscriptionUpgradeData: error,
      } as SubscribeReducersInterface
    }
    case SUBSCRIPTION_UPGRADE.RESET: {
      return {
        ...state,
        subscriptionUpgradeStatus: '',
        subscriptionUpgradeData: null,
      } as SubscribeReducersInterface
    }
    default:
      return state
  }
}

export default subscribeReducer
