import React from 'react'
import Box from './Box'

export default function Issues() {
  return (
    <div className='issue-main d-flex flex-column'>
      <div className='issue-text d-flex flex-column'>
        <h1>
          <span>What</span> makes exposure an issue?
        </h1>
        <p>
          You and your family may encounter nuisances like spam communications, as well as more
          severe risks such as identity theft, physical and online stalking, and even home
          invasions.
        </p>
      </div>
      <div className='issue-boxes'>
        <Box
          issueImage={require('../../assets/images/landingPage/issue-1.svg').default}
          nameName='Span'
        />
        <Box
          issueImage={require('../../assets/images/landingPage/issue-2.svg').default}
          nameName='Identify theft'
        />
        <Box
          issueImage={require('../../assets/images/landingPage/issue-3.svg').default}
          nameName='Stalking'
        />
        <Box
          issueImage={require('../../assets/images/landingPage/issue-4.svg').default}
          nameName='Home attacks'
        />
      </div>
    </div>
  )
}
