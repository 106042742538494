import React, { FC, useEffect, useState } from 'react'
import './styles.scss'
import ProfileTopSection from '../ProfileTopSection'
import { ProfilePlanSectionInterface } from './interfaces'
import { useNavigate } from 'react-router-dom'
import { Button, Tooltip } from 'antd'
import Payment from './Payment'
import Spinner from '../../../../core-ui/Spinner'
import dayjs from 'dayjs'
import SuccessMessage from '../../../SuccessMessage/Index'

const ProfilePlanSection: FC<ProfilePlanSectionInterface> = ({
  accountDeleteStatus,
  cancelSubscription,
  cancelSubscriptionStatus,
  profileInfoNoPendingData,
  upgradeSubscription,
  subscriptionUpgradeStatus,
  profileInfoNoPendingStatus,
  subscriptionUpgradeData,
  upgradeSubscriptionReset,
  successMessage,
  resetSubscription,
  errorMessage,
  resumeSubscriptionStatus,
  subscriptionResumeAction,
  setModalOpen,
}) => {
  const navigate = useNavigate()
  const [annualState, setAnnualState] = useState('plan')
  const handleClickAnnual = () => {
    setAnnualState('payment')
  }

  useEffect(() => {
    return () => {
      upgradeSubscriptionReset()
      resetSubscription()
    }
  }, [])

  useEffect(() => {
    if (accountDeleteStatus === 'success') {
      navigate('/')
    }
  }, [accountDeleteStatus])
  const handleClickPause = () => {
    cancelSubscription()
  }

  // Monthly on Dec 10 (Ends Dec 10, 2023)
  const subscriptionType =
    profileInfoNoPendingData?.data.subscription?.subscriptionType === 'yearly'
      ? 'Annual'
      : 'Monthly'
  const endDate = profileInfoNoPendingData?.data.subscription?.periodEndDate
  const date = `${subscriptionType} on ${dayjs(endDate).format('MMM DD')} (Ends ${dayjs(
    endDate,
  ).format('MMM DD, YYYY')})`

  const handleClickResume = () => {
    subscriptionResumeAction()
  }

  const handleClickDelete = () => {
    setModalOpen(true)
  }

  const pausedDate = `end on ${dayjs(endDate).format('MMM DD, YYYY')}`

  return (
    <>
      <div className='profile'>
        {/* <ProfileTopSection
          type={profileInfoNoPendingData?.data.subscription.isPaused ? 'security' : annualState}
          title='Current plan'
          description={date}
          onClick={handleClickAnnual}
          buttonTitle={
            profileInfoNoPendingData?.data.subscription?.subscriptionType === 'yearly'
              ? 'Switch plan'
              : 'Upgrade to Annual'
          }
        /> */}
        {annualState === 'payment' ? (
          <Payment
            subscriptionUpgradeStatus={subscriptionUpgradeStatus}
            upgradeSubscription={upgradeSubscription}
            profileInfoNoPendingData={profileInfoNoPendingData}
            profileInfoNoPendingStatus={profileInfoNoPendingStatus}
            subscriptionUpgradeData={subscriptionUpgradeData}
          />
        ) : (
          <>
            {successMessage && cancelSubscriptionStatus === 'success' ? (
              <div className=' mt-4'>
                <SuccessMessage message={successMessage} />
              </div>
            ) : (
              ''
            )}
            {profileInfoNoPendingData?.data.subscription.isPaused ? (
              <div className='account account-canceled'>
                <h1>Your subscription is currently paused</h1>
                <p>Your current subscription will {pausedDate}</p>
                <div className='button-box'>
                  <Button onClick={handleClickDelete}>
                    {accountDeleteStatus === 'pending' ? <Spinner /> : 'Delete account'}
                  </Button>
                  <Button onClick={handleClickResume}>
                    {resumeSubscriptionStatus === 'pending' ? <Spinner /> : 'Resume Subscription'}
                  </Button>
                </div>
              </div>
            ) : (
              <div className='account'>
                <h1>Pause account</h1>
                <p>
                  When you pause your account, we will stop the automatic payments and your
                  subscription will end at the end of the cycle.
                </p>
                <div className='hr-box' />
                <div className='bottom-box'>
                  <div className='current-plan-btn'>
                    {/* <Tooltip title='currently this feature is unavailable'> */}
                    <Button onClick={handleClickPause} disabled>
                      {cancelSubscriptionStatus === 'pending' ? <Spinner /> : 'Pause account'}
                    </Button>
                    {/* </Tooltip> */}
                    {cancelSubscriptionStatus !== 'pending' && errorMessage ? (
                      <p className='error-message message-active'>{errorMessage}</p>
                    ) : (
                      <p className='error-message message-disable'>{errorMessage}</p>
                    )}
                  </div>
                </div>
                <h1 className='delete-title'>Delete account</h1>
                <p>
                  When you delete your account your subscription will be cancelled at the end of the
                  cycle, and all your data will be completely deleted from our systems.
                </p>
                <div className='hr-box' />
                <div className='bottom-box'>
                  <div className='current-plan-btn'>
                    <Button onClick={handleClickDelete}>
                      {accountDeleteStatus === 'pending' ? <Spinner /> : 'Delete account'}
                    </Button>
                    {cancelSubscriptionStatus !== 'pending' && errorMessage ? (
                      <p className='error-message message-active'>{errorMessage}</p>
                    ) : (
                      <p className='error-message message-disable'>{errorMessage}</p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ProfilePlanSection
