import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/styles/style.scss'
import store from './store'
import Auth from './utils/auth'
import { Amplify, Logger } from 'aws-amplify'
import { Provider } from 'react-redux'

Amplify.configure(Auth)
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

Logger.LOG_LEVEL = {
  Auth: 'ERROR',
}
Amplify.Auth.ErrorHandler = {
  // Override the default error handler with a no-op function
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  error: () => {},
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
)
