/* eslint-disable camelcase */
import { Button } from 'antd'
import { ResultRemoveAction } from '../../store/actions/ResultActions'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../core-ui/Spinner'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { CombineInterface, DetailsInterface } from '../../store/reducers/interfaces'
import ImageLoader from '../LazyLoader/ImageLoader'
import { RecordsTableInterface } from './interfaces'
import moment from 'moment'

// import './recordTableStyle.scss'

export default function RecordsTable({
  details,
  website,
  isActive,
  setIsActive,
  webLogos,
  index = -1,
  updated_at
}: RecordsTableInterface) {
  const { resultRemoveStatus } = useSelector((state: CombineInterface) => state.resultReducer)
  const { profileInfoNoPendingData } = useSelector((state: any) => state.profileReducer)
  const [selectIndex, setSelectIndex] = useState<number>()
  const [web, setWeb] = useState<string[]>([])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const webLogo = webLogos?.find((item: any) => item?.name == website)?.logo
  
  return (
    <>
      {[Object.values(details).flat()].map((items: any, idx: number) => {
        return (
          <div key={idx}>
            <div
              className='global-box '
              onClick={() => {
                setWeb((prev) => {
                  let update = [...prev]
                  update = prev.includes(website)
                    ? update.filter((item: string) => item !== website)
                    : [...update, website]
                  return update
                })
                !isActive.length && setIsActive([0])
              }}
            >
              <div className='overflow-hidden'>
                {webLogo && (
                  <ImageLoader
                    className='vector'
                    src={
                      webLogo
                        ? webLogo
                        : require('../../assets/images/result/website/radaris.svg').default
                    }
                  />
                )}
              </div>
              <p>www.{website}.com</p>
              <div className='mobile-view'>
                <div className='overflow-hidden'>
                  {webLogo && (
                    <img
                      className='vector'
                      src={
                        webLogo
                          ? webLogo
                          : require('../../assets/images/result/website/radaris.svg').default
                      }
                    />
                  )}
                </div>
                <p>www.{website}.com</p>
              </div>
              <div>
                <img
                  className='down'
                  src={require('../../assets/images/icon/down.svg').default}
                  style={{
                    rotate: isActive.includes(index) && web.includes(website) ? '180deg' : '0deg',
                    transition: 'all .3s linear',
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display: 'grid',
                gridTemplateRows: isActive.includes(index) && web.includes(website) ? '1fr' : '0fr',
                height: isActive.includes(index) && web.includes(website) ? '100%' : '0',
                transition: `all ${Object.values(details).length * 0.38}s linear`,
                background: '#F2FAFD',
                overflow: 'hidden',
              }}
            >
              <div
                className='overflow-hidden bg-white'
                style={{
                  margin: isActive.includes(idx) ? '10px' : 0,
                  transition: 'all 0.3s linear',
                }}
              >
                {items.map(
                  (
                    {
                      age,
                      address,
                      state,
                      city,
                      firstName,
                      middleName,
                      lastName,
                      url,
                      removalStatus='pending',
                      phoneNumber,
                      removalRequestedOn,
                    }: DetailsInterface,
                    index: number,
                  ) =>
                    url ? (
                      <div
                        className='d-flex gap-3 align-items-lg-center flex-column justify-content-lg-between flex-lg-row'
                        key={index}
                      >
                        <div className='all-section'>
                          <div className='middle-box'>
                            <span className='name-state'>
                              {firstName} {lastName}
                            </span>
                            <p>
                              Age:<span>{age}</span>
                            </p>
                            {!!phoneNumber?.length && (
                              <p>
                                Phone Number:
                                <span>{phoneNumber?.join(' , ')}</span>
                              </p>
                            )}
                            {middleName && (
                              <p>
                                Middle Name:<span>{middleName}</span>
                              </p>
                            )}
                            <p>
                              Location:
                              <span>
                                {/* {address} */}
                                {city}, {state}
                              </span>
                            </p>
                          </div>
                        </div>

                          <div className='d-below-sm-flex d-sm-flex d-md-flex justify-content-between gap-1'>
                            <span>
                              {removalStatus == 'completed' ? 'Removed on' : 'Removal Requested on'}
                            </span>
                            <span>
                              {moment(removalRequestedOn||updated_at).format('MM/DD')}
                            </span>
                          </div>

                        <div
                          onClick={() => {
                            setSelectIndex(index)
                            profileInfoNoPendingData
                              ? dispatch(
                                  ResultRemoveAction({ profileUrl: url, website: website }) as any,
                                )
                              : toast.info('Please Register First', { toastId: 'login' })
                         
                          }}
                        >
                       <Button
                            style={{
                              background:
                                removalStatus == 'pending'
                                  ? '#DF970C'
                                  : removalStatus == 'completed'
                                  ? '#28C771'
                                  : '',
                            }}
                            disabled={!!removalStatus}
                          >
                            <span className='mx-auto'>
                              {resultRemoveStatus == 'pending' && index == selectIndex ? (
                                <Spinner />
                              ) : (
                                removalStatus || 'This is me'
                              )}
                            </span>
                          </Button>
                        </div>
                      </div>
                    ) : (
                      items.length <= 1 && (
                        <div className='global-box'>
                          <div className='check-state'>
                            <div className='info-state'>
                              {webLogo && (
                                <img
                                  className='vector'
                                  src={
                                    require('../../assets/images/result/website/radaris.svg')
                                      .default
                                  }
                                />
                              )}
                              <p>{website}</p>
                            </div>
                          </div>
                          <div className='all-section global-match-found align-items-center'>
                            <div className='match-found'>
                              <p>No match found</p>
                            </div>
                          </div>
                        </div>
                      )
                    ),
                )}
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
