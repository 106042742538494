export const webLogos = [
  /* eslint-disable */
  {
    name: '_192',
    url: 'https://www.192.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSfb1_1-4zmuOox9q9Tn5TsSReX9ixowiqdfnMmn42mLw&s',
  },
  {
    name: 'arrestfacts',
    url: 'https://www.411.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'backgroundalert',
    url: 'https://www.backgroundalert.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: '',
  },
  {
    name: 'backgroundcheck',
    url: 'https://www.backgroundcheck.run',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'backgroundcheckers',
    url: 'https://www.backgroundcheckers.net',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'beenverified',
    url: 'https://www.beenverified.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'centeda',
    url: 'https://www.centeda.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'checksecrets',
    url: 'https://www.checksecrets.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/checksecrets.png',
  },
  {
    name: 'clubset',
    url: 'https://www.clubset.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'clustrmaps',
    url: 'https://www.clustrmaps.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/clustrmaps.png',
  },
  {
    name: 'corporationwiki',
    url: 'https://www.corporationwiki.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'councilon',
    url: 'https://www.councilon.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'courtcasefinder',
    url: 'https://www.courtcasefinder.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'curadvisor',
    url: 'https://www.curadvisor.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'cyberbackgroundchecks',
    url: 'https://www.cyberbackgroundchecks.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'dataveria',
    url: 'https://www.dataveria.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/dataveria.png',
  },
  {
    name: 'familytreenow',
    url: 'https://www.familytreenow.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/familytreenow.png',
  },
  {
    name: 'fastbackgroundcheck',
    url: 'https://www.fastbackgroundcheck.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'fastpeoplesearch',
    url: 'https://www.fastpeoplesearch.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'findpeoplesearch',
    url: 'https://www.findpeoplesearch.com',
    parent: 'https://www.peoplefinders.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'floridaparcels',
    url: 'https://floridaparcels.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: 'https://website-logos-dev.s3.amazonaws.com/floridaparcels.png',
  },
  {
    name: 'floridaresidentsdirectory',
    url: 'https://www.floridaresidentsdirectory.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'freepeopledirectory',
    url: 'https://www.freepeopledirectory.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/freepeopledirectory.png',
  },
  {
    name: 'gladiknow',
    url: 'https://www.gladiknow.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'healthprovidersdata',
    url: 'https://healthprovidersdata.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: 'https://website-logos-dev.s3.amazonaws.com/healthprovidersdata.png',
  },
  {
    name: 'idtrue',
    url: 'https://www.idtrue.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/idtrue.png',
  },
  {
    name: 'infotracer',
    url: 'https://www.infotracer.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: 'https://website-logos-dev.s3.amazonaws.com/infotracer.png',
  },
  {
    name: 'inmatessearcher',
    url: 'https://www.inmatessearcher.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/inmatessearcher.png',
  },
  {
    name: 'kidslivesafe',
    url: 'https://www.kidslivesafe.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'mylife',
    url: 'https://www.mylife.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/mylife.png',
  },
  {
    name: 'neighborwho',
    url: 'https://www.neighborwho.com',
    parent: 'https://www.beenverified.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'newenglandfacts',
    url: 'https://www.newenglandfacts.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'npiprofile',
    url: 'https://npiprofile.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: '',
  },
  {
    name: 'nuwber',
    url: 'https://www.nuwber.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'officialusa',
    url: 'https://www.officialusa.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/officialusa.png',
  },
  {
    name: 'ohioresidentdatabase',
    url: 'https://www.ohioresidentdatabase.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'opengovny',
    url: 'https://opengovny.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: 'https://website-logos-dev.s3.amazonaws.com/opengovny.png',
  },
  {
    name: 'opennpi',
    url: 'https://opennpi.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: 'https://website-logos-dev.s3.amazonaws.com/opennpi.png',
  },
  {
    name: 'openpublicrecords',
    url: 'https://www.open-public-records.com',
    parent: 'https://www.beenverified.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'ownerly',
    url: 'https://www.ownerly.com',
    parent: 'https://www.beenverified.com',
    status: 'active',
    stable_level: 'low',
    logo: '',
  },
  {
    name: 'peoplebackgroundcheck',
    url: 'https://www.people-background-check.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'peoplefinders',
    url: 'https://www.peoplefinders.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'peoplelooker',
    url: 'https://www.peoplelooker.com',
    parent: 'https://www.beenverified.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'peoplesearch123',
    url: 'https://www.peoplesearch123.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'peoplesearchnow',
    url: 'https://www.peoplesearchnow.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/peoplesearchnow.png',
  },
  {
    name: 'peoplesearchusa',
    url: 'https://www.peoplesearchusa.org',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/peoplesearchusa.png',
  },
  {
    name: 'peoplesmart',
    url: 'https://www.peoplesmart.com',
    parent: 'https://www.beenverified.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'peoplewhiz',
    url: 'https://www.peoplewhiz.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/peoplewhiz.png',
  },
  {
    name: 'peoplewin',
    url: 'https://www.peoplewin.com',
    parent: 'https://www.spokeo.com',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/peoplewin.png',
  },
  {
    name: 'personsearchers',
    url: 'https://www.personsearchers.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'persontrust',
    url: 'https://www.persontrust.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'privateeye',
    url: 'https://www.privateeye.com',
    parent: 'https://www.peoplefinders.com',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/privateeye.png',
  },
  {
    name: 'privaterecords',
    url: 'https://www.privaterecords.net',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/privaterecords.png',
  },
  {
    name: 'pub360',
    url: 'https://www.pub360.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'publicdatausa',
    url: 'https://www.publicdatausa.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'publicrecordsnow',
    url: 'https://www.publicrecordsnow.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/publicrecordsnow.png',
  },
  {
    name: 'quickpeopletrace',
    url: 'https://www.quickpeopletrace.com',
    parent: 'https://www.peoplefinders.com',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/quickpeopletrace.png',
  },
  {
    name: 'radaris',
    url: 'https://www.radaris.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: 'https://website-logos-dev.s3.amazonaws.com/radaris.png',
  },
  {
    name: 'recordsfinder',
    url: 'https://www.recordsfinder.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: 'https://website-logos-dev.s3.amazonaws.com/recordsfinder.png',
  },
  {
    name: 'salespider',
    url: 'https://www.salespider.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: '',
  },
  {
    name: 'sealedrecords',
    url: 'https://www.sealedrecords.net',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/sealedrecords.png',
  },
  {
    name: 'searchpeoplefree',
    url: 'https://www.searchpeoplefree.com',
    parent: 'https://www.cyberbackgroundcheck.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'searchpublicrecords',
    url: 'https://www.searchpublicrecords.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'searchquarry',
    url: 'https://searchquarry.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/searchquarry.png',
  },
  {
    name: 'searchsystems',
    url: 'https://publicrecords.searchsystems.net',
    parent: 'https://www.beenverified.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'searchusapeople',
    url: 'https://www.searchusapeople.com',
    parent: 'https://www.infotracer.com',
    status: 'active',
    stable_level: 'high',
    logo: '',
  },
  {
    name: 'secretinfo',
    url: 'https://www.secretinfo.org',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/secretinfo.png',
  },
  {
    name: 'smartbackgroundchecks',
    url: 'https://www.smartbackgroundchecks.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/smartbackgroundchecks.png',
  },
  {
    name: 'spokeo',
    url: 'https://www.spokeo.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/spokeo.png',
  },
  {
    name: 'spyfly',
    url: 'https://www.spyfly.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'staterecords',
    url: 'https://www.staterecords.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/staterecords.png',
  },
  {
    name: 'telephonedirectories',
    url: 'https://www.telephonedirectories.us',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/telephonedirectories.png',
  },
  {
    name: 'ucc411',
    url: 'https://www.ucc411.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: '',
  },
  {
    name: 'usaofficial',
    url: 'https://usa-official.com',
    parent: '',
    status: 'active',
    stable_level: 'high',
    logo: 'https://website-logos-dev.s3.amazonaws.com/usaofficial.png',
  },
  {
    name: 'usatrace',
    url: 'https://www.usatrace.com',
    parent: 'https://www.peoplefinders.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'usphonebook',
    url: 'https://www.usphonebook.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/usphonebook.png',
  },
  {
    name: 'verecor',
    url: 'https://www.verecor.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'vericora',
    url: 'https://www.vericora.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/vericora.png',
  },
  {
    name: 'veriforia',
    url: 'https://www.veriforia.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'veripages',
    url: 'https://www.veripages.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'weinform',
    url: 'https://www.weinform.org',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: 'https://website-logos-dev.s3.amazonaws.com/weinform.png',
  },
  {
    name: 'wellnut',
    url: 'https://www.wellnut.com',
    parent: 'https://www.radaris.com',
    status: 'active',
    stable_level: '',
    logo: '',
  },
  {
    name: 'yellowpagesdirectory',
    url: 'https://www.yellowpagesdirectory.com',
    parent: '',
    status: 'active',
    stable_level: '',
    logo: '',
  },
]
