import React, { FC } from 'react'
import { SuccessMessageInterface } from './interfaces'

const SuccessMessage: FC<SuccessMessageInterface> = ({ message }) => {
  return (
    <>
      <div className='check-email-box'>
        <img src={require('../../assets/images/profile/check.svg').default} />
        <p>{message}</p>
      </div>
    </>
  )
}

export default SuccessMessage
