import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useDispatch, useSelector } from 'react-redux'
import { FormEvent, useEffect, useState } from 'react'
// import { Button } from 'antd'

import './style.scss'
import { BusinessContactAction } from '../../../store/actions/ContactAction'
import { BusinessContactBody } from '../../../store/actions/interfaces'
// import { companyRegex, emailRegex, messageRegex, nameRegex } from '../../../utils/regex'
import HubspotContactForm from '../../HubSpot/HubspotContactForm'
import InnerPage from '../../InnerPage'
// import { FormInput } from './FormInput'

export default function BusinessSlug() {
  const { contactData } = useSelector((state: any) => state.contactReducer)

  const [body, setBody] = useState<BusinessContactBody>({
    firstName: '',
    companyName: '',
    email: '',
    message: '',
    /* eslint-disable camelcase */
    recaptcha_token: '',
  })
  // const { executeRecaptcha } = useGoogleReCaptcha()

  // const dispatch = useDispatch()

  // const handleSubmit = async (e: FormEvent) => {
  //   e.preventDefault()
  //   if (!executeRecaptcha) {
  //     return
  //   }
  //   const token = await executeRecaptcha()
  //   dispatch(BusinessContactAction({ ...body, recaptcha_token: token }) as any)
  // }

  useEffect(() => {
    if (
      contactData?.data?.msg ||
      contactData?.error ==
        'You already have a active query please wait for atleast one day to submit a new query.'
    ) {
      setBody({
        firstName: '',
        companyName: '',
        email: '',
        message: '',
        /* eslint-disable camelcase */
        recaptcha_token: '',
      })
    }
  }, [contactData?.data?.msg, contactData?.error])
  return (
    <>
      <InnerPage isSlugLanding={true}>
        <div className='landing-intro-main'>
          <div className='intro'>
            <h3>Welcome to Hackfree.</h3>
            <p>We specialize in consumer and employee privacy and protection services.</p>
            <p>
              {`We offer a variety of solutions to meet your needs including APIs into our platform,
              white-label configurations and our own dashboards.`}
            </p>
            <p>{'We love working with businesses of all types - so please reach out.'}</p>
          </div>
          <HubspotContactForm />
        </div>
      </InnerPage>
    </>
  )
}

// <form className='business-form' onSubmit={handleSubmit}>
// <FormInput
//   placeholder='Enter your name'
//   label='Name'
//   pattern={nameRegex}
//   title='Name Cannot be Less than 3 letters'
//   type='text'
//   name='name'
//   value={body.firstName}
//   onChange={(e) =>
//     setBody((prev: BusinessContactBody) => {
//       const update = { ...prev }
//       update.firstName = e.target.value
//       return update
//     })
//   }
// />
// <FormInput
//   placeholder='Enter your company'
//   label='Company'
//   pattern={companyRegex}
//   title='Company name Cannot be Less than 3 letters'
//   type='text'
//   name='company'
//   value={body.companyName}
//   onChange={(e) =>
//     setBody((prev: BusinessContactBody) => {
//       const update = { ...prev }
//       update.companyName = e.target.value
//       return update
//     })
//   }
// />
// {/* eslint-disable no-useless-escape  */}
// <FormInput
//   placeholder='Enter your email'
//   label='Email'
//   title='Please Enter a valid email'
//   type='email'
//   name='email'
//   value={body.email}
//   pattern={emailRegex}
//   onChange={(e) =>
//     setBody((prev: BusinessContactBody) => {
//       const update = { ...prev }
//       update.email = e.target.value
//       return update
//     })
//   }
// />
// {/* eslint-enable */}
// <FormInput
//   placeholder='Enter your message'
//   label='Tell us how we can help you'
//   pattern={messageRegex}
//   title='Message should between 10-100 letters'
//   type='text'
//   name='message'
//   value={body.message}
//   onChange={(e) =>
//     setBody((prev: BusinessContactBody) => {
//       const update = { ...prev }
//       update.message = e.target.value
//       return update
//     })
//   }
//   isTextArea={true}
// />
// {contactData?.error && <span className='captcha-error'>*{contactData?.error}</span>}
// {contactData?.data && (
//   <span className='captcha-success'>*{contactData?.data?.msg}</span>
// )}
// <Button htmlType='submit'>Submit</Button>
// </form>
