import React from 'react'
import './styles.scss'

const PageNotFound = () => {
  return (
    <div className='page-not-found-container'>
      <div className='container'>
        <h2>404 Error, nothing found</h2>
      </div>
    </div>
  )
}

export default PageNotFound
