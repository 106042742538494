import { FC, useEffect } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import InnerPage from '../../../InnerPage'
import ProfileNavbar from '../ProfileNavbar'
import ProfileSecuritySection from './ProfileSecurity'
import { ChangePassword, ChangePasswordReset } from '../../../../store/actions/AuthActions'
import { ChangePasswordInterface } from '../../../../store/actions/interfaces'
import { CombineInterface } from '../../../../store/reducers/interfaces'
import { ProfileSecurityInterface } from './interfaces'
import { NewProfileInfoNoPendingAction } from '../../../../store/actions/ProfileAction'

const ProfileSecurity: FC<ProfileSecurityInterface> = ({
  authReducer: { changePasswordStatus, changePasswordData },
  changePasswordAction,
  NewProfileInfoNoPendingAction,
  profileReducer: { profileInfoNoPendingData },
  changePasswordResetAction,
}) => {
  return (
    <>
      <InnerPage>
        <div className='profile-section'>
          <div className='container'>
            <div className='profile-section-child'>
              <ProfileNavbar />
              <ProfileSecuritySection
                changePasswordStatus={changePasswordStatus}
                changePasswordAction={changePasswordAction}
                changePasswordError={changePasswordData?.message || ''}
                email={profileInfoNoPendingData?.data.email[0].value || ''}
                changePasswordResetAction={changePasswordResetAction}
              />
            </div>
          </div>
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ authReducer, profileReducer }: CombineInterface) => ({
  authReducer,
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  changePasswordAction: (body: ChangePasswordInterface) => dispatch(ChangePassword(body)),
  NewProfileInfoNoPendingAction: () => dispatch(NewProfileInfoNoPendingAction()),
  changePasswordResetAction: () => dispatch(ChangePasswordReset()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(ProfileSecurity)
