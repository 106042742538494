import { useState } from 'react'
import { FormProps } from './interface'
import { nameRegex } from '../../../utils/regex'
const LandingPageForm = ({ formData, handleChange, handleSubmit }: FormProps) => {
  const [formValidation, setFormValidation] = useState({
    companyName: true,
    websiteUrl: true,
    pocName: true,
    pocEmailAddress: true,
    pocTitle: true,
    pocPhone: true,
    estimateUsers: true,
  })
  return (
    <div>
      <div className='form-validation'>
        <div
          style={{
            marginBottom: '20px',
          }}
        >
          <label htmlFor='companyName'>
            Company Name<span>*</span>
          </label>
          <input
            style={
              {
                border: !formValidation.companyName && '2px solid rgb(203, 56, 30)',
              } as any
            }
            type='text'
            id='companyName'
            name='companyName'
            placeholder='Company Name'
            value={formData.companyName}
            pattern={nameRegex.source}
            onChange={handleChange}
            required
            autoFocus
            onBlur={(e) =>
              setFormValidation((prev: any) => {
                const update = { ...prev }
                update.companyName = e.target.checkValidity()
                return update
              })
            }
            onFocus={() =>
              setFormValidation((prev: any) => {
                const update = { ...prev }
                update.companyName = true
                return update
              })
            }
          />
          {!formValidation.companyName && <span>*Name Cannot be less than 3 letters</span>}
        </div>
        <div className='input-row'>
          <div className='input-left'>
            <label htmlFor='websiteURL'>
              Website URL<span>*</span>
            </label>
            <input
              style={
                {
                  border: !formValidation.websiteUrl && '2px solid rgb(203, 56, 30)',
                } as any
              }
              type='url'
              id='websiteURL'
              name='websiteUrl'
              placeholder='www.companyname.com'
              value={formData.websiteUrl}
              onChange={handleChange}
              required
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.websiteUrl = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.websiteUrl = true
                  return update
                })
              }
            />
            {!formValidation.websiteUrl && (
              <span>*Please Enter a valid url start with https://</span>
            )}
          </div>
          <div className='input-right'>
            <label htmlFor='poc'>
              POC Name<span>*</span>
            </label>
            <input
              style={
                {
                  border: !formValidation.pocName && '2px solid rgb(203, 56, 30)',
                } as any
              }
              type='text'
              id='poc'
              name='pocName'
              placeholder='Ex. John'
              value={formData.pocName}
              onChange={handleChange}
              required
              pattern={nameRegex.source}
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocName = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocName = true
                  return update
                })
              }
            />
            {!formValidation.pocName && <span>*Name Cannot be less than 3 letters</span>}
          </div>
        </div>
        <div className='input-row'>
          <div className='input-left'>
            <label htmlFor='pocEmail'>
              POC Email Address<span>*</span>
            </label>
            <input
              style={
                {
                  border: !formValidation.pocEmailAddress && '2px solid rgb(203, 56, 30)',
                } as any
              }
              type='email'
              id='pocEmail'
              name='pocEmailAddress'
              placeholder='example@gmail.com'
              value={formData.pocEmailAddress}
              onChange={handleChange}
              required
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocEmailAddress = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocEmail = true
                  return update
                })
              }
            />
            {!formValidation.pocEmailAddress && <span>*Please Enter a Valid Company email</span>}
          </div>
          <div className='input-right'>
            <label htmlFor='pocTitle'>
              POC Title<span>*</span>
            </label>
            <input
              style={
                {
                  border: !formValidation.pocTitle && '2px solid rgb(203, 56, 30)',
                } as any
              }
              type='text'
              id='pocTitle'
              name='pocTitle'
              placeholder='Ex. Company Name'
              value={formData?.pocTitle}
              onChange={handleChange}
              required
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocTitle = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocTitle = true
                  return update
                })
              }
              pattern={nameRegex.source}
            />
            {!formValidation.pocTitle && <span>*Poc Title Cannot be less than 3 letters</span>}
          </div>
        </div>
        <div className='input-row'>
          <div className='input-left'>
            <label htmlFor='pocPhone'>
              POC Phone<span>*</span>
            </label>
            <input
              style={
                {
                  border: !formValidation.pocPhone && '2px solid rgb(203, 56, 30)',
                } as any
              }
              type='tel'
              id='pocPhone'
              name='pocPhone'
              placeholder='Ex +1 021 225641'
              value={formData?.pocPhone}
              onChange={handleChange}
              required
              onBlur={(e) =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocPhone = e.target.checkValidity()
                  return update
                })
              }
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.pocPhone = true
                  return update
                })
              }
            />
            {!formValidation.pocPhone && <span>*Please Enter the correct format</span>}
          </div>
          <div className='input-right'>
            <label htmlFor='estimatedUsers'>
              Estimated Users<span>*</span>
            </label>
            <input
              style={
                {
                  border: !formValidation.estimateUsers && '2px solid rgb(203, 56, 30)',
                } as any
              }
              type='number'
              id='estimatedUsers'
              name='estimateUsers'
              placeholder='e.g, 8'
              value={formData.estimateUsers}
              onChange={handleChange}
              required
              min={1}
              max={8}
              onBlur={(e) => {
                console.log(e.target.checkValidity())
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.estimateUsers = e.target.checkValidity()
                  return update
                })
              }}
              onFocus={() =>
                setFormValidation((prev: any) => {
                  const update = { ...prev }
                  update.estimateUsers = true
                  return update
                })
              }
            />
            {!formValidation.estimateUsers && <span>*Users Cannot Exceed 8</span>}
          </div>
        </div>
        <div className='input-file-row'>
          <div className='input-left'>
            <div className='file-upload' style={{ position: 'relative' }}>
              <label htmlFor='logoUpload' className='upload-label'>
                {formData.logo ? formData.logo.name : '*Upload your logo'}
              </label>
              <label htmlFor='logoUpload' className='custom-file-upload'>
                Browse files
              </label>
              <input
                type='file'
                id='logoUpload'
                name='logo'
                onChange={handleChange}
                accept='image/*'
                required
              />
            </div>
          </div>
          <div className='input-right'>
            <label htmlFor='bgColor'>Button Background Color</label>
            <div className='color-picker mb-3'>
              <span>{formData?.buttonBackgroundColor}</span>
              <input
                type='color'
                name='buttonBackgroundColor'
                value={formData.buttonBackgroundColor}
                onChange={handleChange}
              />
            </div>
            <label htmlFor='textColor'>Button Text Color</label>
            <div className='color-picker'>
              <span>{formData?.buttonTextColor}</span>
              <input
                type='color'
                name='buttonTextColor'
                value={formData?.buttonTextColor}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPageForm
