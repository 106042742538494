import './style.scss'

export default function RecordBoxes({ status, textColor, bgColor, total }: any) {
  return (
    <div className='record-box' style={{ background: bgColor }}>
      <h1 style={{ color: textColor }}>{total}</h1>
      <p>Records {status}</p>
    </div>
  )
}
