import { toast } from 'react-toastify'
import { HTTP } from '../../utils/http'
import { CONTACT } from '../constants'
import { BusinessContactBody, ContactBody, sendFeedbackInterface } from './interfaces'
import { SetStateAction } from 'react'

export const ContactAction =
  (body: ContactBody, setFormData?: React.Dispatch<SetStateAction<any>>) =>
  async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: CONTACT.PENDING })
      const response = await HTTP.post('api/support/contact-us', body)
      dispatch({ type: CONTACT.SUCCESS, payload: response?.data })
      setFormData &&
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          message: '',
        })
      response.data?.data?.msg !==
        'You already have a active query please wait for atleast one day to submit a new query.' &&
        toast.info(response?.data?.data?.msg || 'something missing', { toastId: 'success' })
    } catch (error: any) {
      dispatch({ type: CONTACT.ERROR, error: error })
      toast.error(error?.response?.data?.error || error.message, { toastId: 'ContactAction' })
    }
  }

export const BusinessContactAction =
  (body: BusinessContactBody) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: CONTACT.PENDING })

      const response = await HTTP.post('api/business_support/contact-us', body)
      dispatch({ type: CONTACT.SUCCESS, payload: response.data })
      setTimeout(() => {
        dispatch({ type: CONTACT.SUCCESS, payload: '' })
      }, 5000)
    } catch (error: any) {
      dispatch({ type: CONTACT.ERROR, error: error })
      setTimeout(() => {
        dispatch({ type: CONTACT.SUCCESS, payload: '' })
      }, 5000)
      toast.error(error?.response?.data?.error || error.message, { toastId: 'ContactAction' })
    }
  }

export const sendFeedback =
  (body: sendFeedbackInterface) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: CONTACT.PENDING })

      const response = await HTTP.post('api/profile/support/message ', body)
      dispatch({ type: CONTACT.SUCCESS, payload: response.data })
      toast.success('Message sent Successfully')
      setTimeout(() => {
        dispatch({ type: CONTACT.SUCCESS, payload: '' })
      }, 5000)
    } catch (error: any) {
      dispatch({ type: CONTACT.ERROR, error: error })
      setTimeout(() => {
        dispatch({ type: CONTACT.SUCCESS, payload: '' })
      }, 5000)
      toast.error(error?.response?.data?.error || error.message, { toastId: 'ContactAction' })
    }
  }

export const ContactReset = () => ({
  type: CONTACT.RESET,
})
