export const SIGN_UP = {
  PENDING: 'SIGN_UP_PENDING',
  SUCCESS: 'SIGN_UP_SUCCESS',
  ERROR: 'SIGN_UP_ERROR',
  RESET: 'SIGN_UP_RESET',
}

export const VERIFY_PHONE = {
  PENDING: 'VERIFY_PHONE_PENDING',
  SUCCESS: 'VERIFY_PHONE_SUCCESS',
  ERROR: 'VERIFY_PHONE_ERROR',
  RESET: 'VERIFY_PHONE_RESET',
}

export const LOGO = {
  PENDING: 'LOGO_PENDING',
  SUCCESS: 'LOGO_SUCCESS',
  ERROR: 'LOGO_ERROR',
  RESET: 'LOGO_RESET',
}

export const SIGN_IN = {
  PENDING: 'SIGN_IN_PENDING',
  SUCCESS: 'SIGN_IN_SUCCESS',
  ERROR: 'SIGN_IN_ERROR',
  RESET: 'SIGN_IN_RESET',
}

export const FORGOT_PASSWORD = {
  PENDING: 'FORGOT_PASSWORD_PENDING',
  SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  ERROR: 'FORGOT_PASSWORD_ERROR',
  RESET: 'FORGOT_PASSWORD_RESET',
  RESEND_STATUS: 'RESEND_FORGOT_PASSWORD_PENDING',
  RESEND_STATUS_REPORT: 'RESEND_FORGOT_PASSWORD_PENDING_REPORT',
}

export const CONFIRM_FORGOT_PASSWORD = {
  PENDING: 'CONFIRM_FORGOT_PASSWORD_PENDING',
  SUCCESS: 'CONFIRM_FORGOT_PASSWORD_SUCCESS',
  ERROR: 'CONFIRM_FORGOT_PASSWORD_ERROR',
  RESET: 'CONFIRM_FORGOT_PASSWORD_RESET',
}

export const ADD_CAMPAIGN = {
  PENDING: 'ADD_CAMPAIGN_PENDING',
  SUCCESS: 'ADD_CAMPAIGN_SUCCESS',
  ERROR: 'ADD_CAMPAIGN_ERROR',
  RESET: 'ADD_CAMPAIGN_RESET',
}
export const GET_CAMPAIGNS = {
  PENDING: 'GET_CAMPAIGNS_PENDING',
  SUCCESS: 'GET_CAMPAIGNS_SUCCESS',
  ERROR: 'GET_CAMPAIGNS_ERROR',
  RESET: 'GET_CAMPAIGNS_RESET',
}

export const GET_CAMPAIGNS_VIEW = {
  PENDING: 'GET_CAMPAIGNS_VIEW_PENDING',
  SUCCESS: 'GET_CAMPAIGNS_VIEW_SUCCESS',
  ERROR: 'GET_CAMPAIGNS_VIEW_ERROR',
  RESET: 'GET_CAMPAIGNS_VIEW_RESET',
}

export const USER_INFO = {
  PENDING: 'USER_INFO_PENDING',
  SUCCESS: 'USER_INFO_SUCCESS',
  ERROR: 'USER_INFO_ERROR',
  RESET: 'USER_INFO_RESET',
}

export const GET_CAMPAIGNS_USER = {
  PENDING: 'GET_CAMPAIGNS_USER_PENDING',
  SUCCESS: 'GET_CAMPAIGNS_USER_SUCCESS',
  ERROR: 'GET_CAMPAIGNS_USER_ERROR',
  RESET: 'GET_CAMPAIGNS_USER_RESET',
}

export const ADD_GROUPS = {
  PENDING: 'ADD_GROUPS_PENDING',
  SUCCESS: 'ADD_GROUPS_SUCCESS',
  ERROR: 'ADD_GROUPS_ERROR',
  RESET: 'ADD_GROUPS_RESET',
}

export const GET_GROUPS = {
  PENDING: 'GET_GROUPS_PENDING',
  SUCCESS: 'GET_GROUPS_SUCCESS',
  ERROR: 'GET_GROUPS_ERROR',
  RESET: 'GET_GROUPS_RESET',
}

export const ADD_VENUES = {
  PENDING: 'ADD_VENUES_PENDING',
  SUCCESS: 'ADD_VENUES_SUCCESS',
  ERROR: 'ADD_VENUES_ERROR',
  RESET: 'ADD_VENUES_RESET',
}

export const GET_VENUES = {
  PENDING: 'GET_VENUES_PENDING',
  SUCCESS: 'GET_VENUES_SUCCESS',
  ERROR: 'GET_VENUES_ERROR',
  RESET: 'GET_VENUES_RESET',
}

export const ADD_USER = {
  PENDING: 'ADD_USER_PENDING',
  SUCCESS: 'ADD_USER_SUCCESS',
  ERROR: 'ADD_USER_ERROR',
  RESET: 'ADD_USER_RESET',
}

export const SEARCH = {
  PENDING: 'SEARCH_PENDING',
  SUCCESS: 'SEARCH_SUCCESS',
  ERROR: 'SEARCH_ERROR',
  RESET: 'SEARCH_RESET',
}

export const BUSINESS = {
  PENDING: 'BUSINESS_PENDING',
  SUCCESS: 'BUSINESS_SUCCESS',
  ERROR: 'BUSINESS_ERROR',
  RESET: 'BUSINESS_RESET',
}

export const RESULT_LIST = {
  PENDING: 'RESULT_LIST_PENDING',
  SUCCESS: 'RESULT_LIST_SUCCESS',
  ERROR: 'RESULT_LIST_ERROR',
  RESET: 'RESULT_LIST_RESET',
}

export const SUBSCRIBE_PAYMENT = {
  PENDING: 'SUBSCRIBE_PAYMENT_PENDING',
  SUCCESS: 'SUBSCRIBE_PAYMENT_SUCCESS',
  ERROR: 'SUBSCRIBE_PAYMENT_ERROR',
  RESET: 'SUBSCRIBE_PAYMENT_RESET',
}

export const RESULT_REMOVE = {
  PENDING: 'RESULT_REMOVE_PENDING',
  SUCCESS: 'RESULT_REMOVE_SUCCESS',
  ERROR: 'RESULT_REMOVE_ERROR',
  RESET: 'RESULT_REMOVE_RESET',
}

export const CONTACT = {
  PENDING: 'CONTACT_PENDING',
  SUCCESS: 'CONTACT_SUCCESS',
  ERROR: 'CONTACT_ERROR',
  RESET: 'CONTACT_RESET',
}

export const PROFILE = {
  PENDING: 'PROFILE_PENDING',
  SUCCESS: 'PROFILE_SUCCESS',
  ERROR: 'PROFILE_ERROR',
  RESET: 'PROFILE_RESET',
}
export const ALERT = {
  START: 'ALERT_START',
  END: 'ALERT_END',
}

export const SUB_PROFILE = {
  PENDING: 'SUB_PROFILE_PENDING',
  SUCCESS: 'SUB_PROFILE_SUCCESS',
  DELETE: 'SUB_PROFILE_DELETE',
  ERROR: 'SUB_PROFILE_ERROR',
  RESET: 'SUB_PROFILE_RESET',
}
export const PROFILE_INFO_NO_PENDING = {
  PENDING: 'PROFILE_INFO_NO_PENDING_PENDING',
  SUCCESS: 'PROFILE_INFO_NO_PENDING_SUCCESS',
  ERROR: 'PROFILE_INFO_NO_PENDING_ERROR',
  RESET: 'PROFILE_INFO_NO_PENDING_RESET',
}

export const PROFILE_RESULTS_NO_PENDING = {
  PENDING: 'PROFILE_RESULTS_NO_PENDING_PENDING',
  SUCCESS: 'PROFILE_RESULTS_NO_PENDING_SUCCESS',
  ERROR: 'PROFILE_RESULTS_NO_PENDING_ERROR',
  RESET: 'PROFILE_RESULTS_NO_PENDING_RESET',
}

export const PROFILE_EDIT = {
  PENDING: 'PROFILE_EDIT_PENDING',
  SUCCESS: 'PROFILE_EDIT_SUCCESS',
  ERROR: 'PROFILE_EDIT_ERROR',
  RESET: 'PROFILE_EDIT_RESET',
  DEL_PENDING: 'PROFILE_EDIT_DEL_PENDING',

  DELETE_ONE: 'PROFILE_EDIT_DELETE_ONE',
}

export const DELETE_ACCOUNT = {
  PENDING: 'DELETE_ACCOUNT_PENDING',
  SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  ERROR: 'DELETE_ACCOUNT_ERROR',
  RESET: 'DELETE_ACCOUNT_RESET',
}

export const CANCEL_SUBSCRIPTION = {
  PENDING: 'CANCEL_SUBSCRIPTION_PENDING',
  SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  ERROR: 'CANCEL_SUBSCRIPTION_ERROR',
  RESET: 'CANCEL_SUBSCRIPTION_RESET',
}

export const CHANGE_PASSWORD = {
  PENDING: 'CHANGE_PASSWORD_PENDING',
  SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  ERROR: 'CHANGE_PASSWORD_ERROR',
  RESET: 'CHANGE_PASSWORD_RESET',
}

export const GET_SUBSCRIPTION = {
  PENDING: 'GET_SUBSCRIPTION_PENDING',
  SUCCESS: 'GET_SUBSCRIPTION_SUCCESS',
  ERROR: 'GET_SUBSCRIPTION_ERROR',
  RESET: 'GET_SUBSCRIPTION_RESET',
}

export const SUBSCRIPTION_UPGRADE = {
  PENDING: 'SUBSCRIPTION_UPGRADE_PENDING',
  SUCCESS: 'SUBSCRIPTION_UPGRADE_SUCCESS',
  ERROR: 'SUBSCRIPTION_UPGRADE_ERROR',
  RESET: 'SUBSCRIPTION_UPGRADE_RESET',
}

export const RESEND_SIGN_UP = {
  PENDING: 'RESEND_SIGN_UP_PENDING',
  SUCCESS: 'RESEND_SIGN_UP_SUCCESS',
  ERROR: 'RESEND_SIGN_UP_ERROR',
  RESET: 'RESEND_SIGN_UP_RESET',
}

export const RESUME_SUBSCRIPTION = {
  PENDING: 'RESUME_SUBSCRIPTION_PENDING',
  SUCCESS: 'RESUME_SUBSCRIPTION_SUCCESS',
  ERROR: 'RESUME_SUBSCRIPTION_ERROR',
  RESET: 'RESUME_SUBSCRIPTION_RESET',
}
