import ProfileSecurity from './components/Profile/ProfileSetting/ProfileSecurity'
import ProfileView from './components/Profile/ProfileSetting/ProfileView/index'
import ProfilePlan from './components/Profile/ProfileSetting/ProfilePlan'
import GeneratedPromo from './components/Business/GeneratedPromo/index'
import LandingPageGen from './components/Business/LandingPageGen'
import ForgotPassword from './components/ForgotPassword/index'
import SearchResults from './components/Profile/SearchResults'
import Profile from './components/Profile/ProfileFields/index'
import PrivacyPolice from './components/PrivacyPolicy/index'
import ChooseOption from './components/Business/ChoosePlan'
import PromoCode from './components/Business/PromoCodeGen'
import BusinessOption from './components/Business/Support'
import PageLanding from './components/Business/LandingPage'
import Subscribe from './components/SubscribePlan/index'
import SignUp from './components/Login/SignUp/index'
import SignIn from './components/Login/SignIn/index'
import ContactUs from './components/ContactUs/index'
import PageNotFound from './components/404/index'
import LandingPage from './components/LandingPage'
import Result from './components/Results/index'
import Terms from './components/Terms/index'
import Home from './components/Home/index'
import NewHome from './components/NewHome/index'
import Test from './components/Test/Test'
import Feedback from './components/NewHome/Feedback'
import EmployLogin from './components/Login/EmployLogin'
import Redirect from './components/Redirect/Redirect'
export const router = [
  {
    path: '*',
    element: <PageNotFound />,
    isPrivate: false,
  },
  {
    path: '/',
    element: <NewHome />,
    isPrivate: false,
  },
  // {
  //   path: '/home',
  //   element: <Home />,
  //   isPrivate: false,
  // },

  {
    path: '/aiornot',
    element: <Redirect />,
    isPrivate: false,
  },
  {
    path: '/sign-up',
    element: <SignUp />,
    isPrivate: false,
  },
  {
    path: '/sign-in',
    element: <SignIn />,
    isPrivate: false,
  },
  {
    path: '/create-password',
    element: <EmployLogin />,
    isPrivate: false,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    isPrivate: false,
  },
  {
    path: '/result',
    element: <Result />,
    isPrivate: false,
  },
  {
    path: '/subscribe',
    element: <Subscribe />,
    isPrivate: false,
  },
  {
    path: '/profile',
    element: <Profile />,
    isPrivate: true,
  },
  {
    path: '/contact',
    element: <Feedback />,
    isPrivate: false,
  },
  {
    path: '/privacy-policy',
    element: <PrivacyPolice />,
    isPrivate: false,
  },
  {
    path: '/terms',
    element: <Terms />,
    isPrivate: false,
  },
  {
    path: '/profile-view',
    element: <ProfileView />,
    isPrivate: true,
  },
  {
    path: '/profile-plan',
    element: <ProfilePlan />,
    isPrivate: true,
  },
  {
    path: '/profile-security',
    element: <ProfileSecurity />,
    isPrivate: true,
  },
  {
    path: '/search-results',
    element: <SearchResults />,
    isPrivate: true,
  },
  {
    path: '/landing-page',
    element: <LandingPage />,
    isPrivate: false,
  },
  {
    path: '/business/plan',
    element: <ChooseOption />,
    isPrivate: true,
  },
  {
    path: '/business/contact',
    element: <BusinessOption />,
    isPrivate: false,
  },
  {
    path: '/business/promo-code',
    element: <PromoCode />,
    isPrivate: true,
  },
  {
    path: '/business/promo-code/:promoCode',
    element: <GeneratedPromo />,
    isPrivate: false,
  },
  {
    path: '/business/landing-page-generate',
    element: <LandingPageGen />,
    isPrivate: true,
  },
  {
    path: '/business/landing-page/:id',
    element: <PageLanding />,
    isPrivate: false,
  },
  {
    path: '/test',
    element: <Test />,
    isPrivate: false,
  },
]
