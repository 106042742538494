import { FC, useEffect, useState } from 'react'
import './styles.scss'
import InnerPage from '../../../InnerPage'
import ProfileNavbar from '../ProfileNavbar'
import ProfilePlanSection from './ProfilePlanSection'
import { connect } from 'react-redux'
import {
  AccountDeleteAction,
  cancelSubscription,
  NewProfileInfoNoPendingAction,
  resetSubscription,
  SubscriptionResumeAction,
  SubscriptionResumeResetAction,
} from '../../../../store/actions/ProfileAction'
import { CombineInterface } from '../../../../store/reducers/interfaces'
import { ProfilePlanInterface } from './interfaces'
import {
  upgradeSubscription,
  upgradeSubscriptionReset,
} from '../../../../store/actions/SubscribeActions'
import { PaymentSubscriptionUpgradeBody } from '../../../../store/actions/interfaces'
import Spinner from '../../../../core-ui/Spinner'
import DeleteModal from './DeleteModal'

const ProfilePlan: FC<ProfilePlanInterface> = ({
  profileReducer: {
    accountDeleteStatus,
    cancelSubscriptionStatus,
    profileInfoNoPendingData,
    profileInfoNoPendingStatus,
    cancelSubscriptionData,
    resumeSubscriptionStatus,
  },
  accountDeleteAction,
  cancelSubscription,
  NewProfileInfoNoPendingAction,
  upgradeSubscription,
  upgradeSubscriptionReset,
  resetSubscription,
  subscribeReducer: {
    subscriptionUpgradeStatus: subscriptionUpgradeStatus,
    subscriptionUpgradeData,
  },
  subscriptionResumeAction,
  subscriptionResumeResetAction,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (
      subscriptionUpgradeStatus === 'success' ||
      cancelSubscriptionStatus === 'success' ||
      resumeSubscriptionStatus === 'success'
    ) {
      subscriptionResumeResetAction()
      resetSubscription()
      NewProfileInfoNoPendingAction()
    }
  }, [subscriptionUpgradeStatus, cancelSubscriptionStatus, resumeSubscriptionStatus])

  return (
    <>
      <InnerPage>
        <div className='profile-section'>
          <div className='container'>
            <div className='profile-section-child'>
              <ProfileNavbar />
              {profileInfoNoPendingStatus === 'pending' ? (
                <>
                  <div className='big-spinner'>
                    <Spinner />
                  </div>
                </>
              ) : (
                <>
                  <ProfilePlanSection
                    accountDeleteStatus={accountDeleteStatus}
                    cancelSubscription={cancelSubscription}
                    cancelSubscriptionStatus={cancelSubscriptionStatus}
                    profileInfoNoPendingData={profileInfoNoPendingData}
                    upgradeSubscription={upgradeSubscription}
                    subscriptionUpgradeStatus={subscriptionUpgradeStatus}
                    profileInfoNoPendingStatus={profileInfoNoPendingStatus}
                    upgradeSubscriptionReset={upgradeSubscriptionReset}
                    subscriptionUpgradeData={subscriptionUpgradeData?.data?.message || ''}
                    successMessage={cancelSubscriptionData?.data?.message || ''}
                    errorMessage={cancelSubscriptionData?.error || ''}
                    resetSubscription={resetSubscription}
                    resumeSubscriptionStatus={resumeSubscriptionStatus}
                    subscriptionResumeAction={subscriptionResumeAction}
                    setModalOpen={setModalOpen}
                  />
                  <DeleteModal
                    onSave={() => setModalOpen(false)}
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    accountDeleteStatus={accountDeleteStatus}
                    accountDeleteAction={accountDeleteAction}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ profileReducer, subscribeReducer }: CombineInterface) => ({
  profileReducer,
  subscribeReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  accountDeleteAction: () => dispatch(AccountDeleteAction()),
  cancelSubscription: () => dispatch(cancelSubscription()),
  NewProfileInfoNoPendingAction: () => dispatch(NewProfileInfoNoPendingAction()),
  upgradeSubscription: (body: PaymentSubscriptionUpgradeBody) =>
    dispatch(upgradeSubscription(body)),
  upgradeSubscriptionReset: () => dispatch(upgradeSubscriptionReset()),
  resetSubscription: () => dispatch(resetSubscription()),
  subscriptionResumeAction: () => dispatch(SubscriptionResumeAction()),
  subscriptionResumeResetAction: () => dispatch(SubscriptionResumeResetAction()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(ProfilePlan)
