import { FC } from 'react'
import InnerPage from '../InnerPage'
import InformationInternet from './InformationInternet'
import AnonymousPrivate from './AnonymousPrivate'
import { CombineInterface } from '../../store/reducers/interfaces'
import Statistics from './Statistics'
import { Search, SearchReset } from '../../store/actions/SearchAction'
import Details from './Details'
import { connect } from 'react-redux'
import { SearchBody } from '../../store/actions/interfaces'
import { HomeInterface } from './interfaces'
import HeroSection from './HeroSection'
import Feedback from './Feedback'
import CallToAction from './CallToAction'
import WhoNeed from './WhoNeed'
import ContactAction from './ContactAction'
import './styles.scss'

const Home: FC<HomeInterface> = ({
  searchReducer: { searchStatus, searchData },
  searchAction,
  searchResetAction,
}) => {
  return (
    <>
      <InnerPage>
        <HeroSection />
        <InformationInternet
          requestId={searchData?.data.request_id}
          searchAction={searchAction}
          searchStatus={searchStatus}
          searchResetAction={searchResetAction}
        />
        <AnonymousPrivate />

        <Details />
        {/* <Feedback /> */}
        <CallToAction />
        <WhoNeed />
        <ContactAction />
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ searchReducer }: CombineInterface) => ({
  searchReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  searchAction: (body: SearchBody) => dispatch(Search(body)),
  searchResetAction: () => dispatch(SearchReset()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Home)
