import DealCard from './DealCard'

export default function Deals() {
  return (
    <div className='deals-section'>
      <h1>
        Get <span>Aurora&apos;s</span> Deals
      </h1>
      <p>
        Select your plan and enjoy up to <span>50%</span> off with Hackfree
      </p>
      <div />
      <div className='d-flex position-absolute'>
        <div className='floating-image'>
          <img
            src={require('../../assets/images/landingPage/Tooltip.svg').default}
            alt='floating tooltip'
          />
        </div>
        <DealCard
          frequency='monthly'
          imageLink={require('../../assets/images/landingPage/starter.svg').default}
          planName='Starter'
        />
        <DealCard
          frequency='Yearly'
          imageLink={require('../../assets/images/landingPage/pro.svg').default}
          planName='Pro'
        />
      </div>
    </div>
  )
}
