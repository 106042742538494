import { CTABoxInterface } from './interfaces'

export default function CTABox({ heading, paragraph, SvgImage }: CTABoxInterface) {
  return (
    <div className='position-relative'>
      <div className='svg-image'>{SvgImage}</div>
      <div className='cta-box-main'>
        <h1>{heading}</h1>
        <p>{paragraph}</p>
      </div>
    </div>
  )
}
