const AnonymousPrivate = () => {
  return (
    <div className='anonymous'>
      <div className='container anonymous-private'>
        <h1>hack-free</h1>
        <p>
          Our scrubbing engine is your personal cleanup crew. It scours the web for the info you
          don’t want public
          <br /> and wipes it away. After the cleanup, we’ll send you a report detailing what’s been
          successfully scrubbed.
        </p>
        <p>
          Next, it’s time to shine! Boost your personal brand by promoting the positive results you
          want people to
          <br /> see – like your LinkedIn profile or personal website. Coming soon are add-ons like
          dark web monitoring
          <br /> and digital deepfake detection.
        </p>
        {/* <div className='can-trust'>
          <div>
            <img src={require('../../assets/images/newHome/result.svg').default} />
            <p>
              Results you <br /> can trust
            </p>
          </div>
          <div>
            <img src={require('../../assets/images/newHome/customer.svg').default} />
            <p>
              Customer identity <br /> protection
            </p>
          </div>
          <div>
            <img src={require('../../assets/images/newHome/avoid.svg').default} />
            <p>
              Avoid phone <br /> scammers
            </p>
          </div>
          <div>
            <img src={require('../../assets/images/newHome/privatInfo.svg').default} />
            <p>
              Find and remove your exposed <br /> private information
            </p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default AnonymousPrivate
