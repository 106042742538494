import { HTTP } from '../../utils/http'
import { SUBSCRIBE_PAYMENT, GET_SUBSCRIPTION, SUBSCRIPTION_UPGRADE } from '../constants'
import { SubscribeBody, PaymentSubscriptionUpgradeBody } from './interfaces'

export const getSubscription = (body: SubscribeBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SUBSCRIBE_PAYMENT.PENDING })
    const response = await HTTP.post('api/payment/subscription', body)

    dispatch({ type: SUBSCRIBE_PAYMENT.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: SUBSCRIBE_PAYMENT.ERROR, payload: error })
  }
}

export const checkSubscription = () => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: GET_SUBSCRIPTION.PENDING })
    const response = await HTTP.get('api/payment/subscription')

    dispatch({ type: GET_SUBSCRIPTION.SUCCESS, payload: response.data })
  } catch (error) {
    dispatch({ type: GET_SUBSCRIPTION.ERROR, payload: error })
  }
}

export const upgradeSubscription =
  (body: PaymentSubscriptionUpgradeBody) => async (dispatch: (event: any) => void) => {
    try {
      dispatch({ type: SUBSCRIPTION_UPGRADE.PENDING })
      const response = await HTTP.patch('api/payment/subscription', body)
      dispatch({ type: SUBSCRIPTION_UPGRADE.SUCCESS, payload: response.data })
    } catch (error) {
      dispatch({ type: SUBSCRIPTION_UPGRADE.ERROR, payload: error })
    }
  }

export const upgradeSubscriptionReset = () => ({
  type: SUBSCRIPTION_UPGRADE.RESET,
})

export const resetSubscription = () => ({
  type: SUBSCRIBE_PAYMENT.RESET,
})
