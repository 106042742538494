import { Button } from 'antd'

export default function Help() {
  return (
    <div className='help-section'>
      <h1>
        How Hackfree <span>Helps</span>
      </h1>
      <p>
        We eliminate your private listings from platforms like MyLife, WhitePages, Spokeo,
        BeenVerified, and over 80 others. This ensures that individuals with questionable intentions
        won&apos;t be able to access your personal information.
      </p>
      <Button>Grab Deal</Button>
      <img
        src={require('../../assets/images/landingPage/help.svg').default}
        alt='help issue'
        className='w-100'
      />
    </div>
  )
}
