import { toast } from 'react-toastify'
import { HTTP } from '../../utils/http'
import { SEARCH } from '../constants'
import { SearchBody } from './interfaces'

export const Search = (body: SearchBody) => async (dispatch: (event: any) => void) => {
  try {
    dispatch({ type: SEARCH.PENDING })
    const response = await HTTP.post('api/data/search-profile', body)

    dispatch({ type: SEARCH.SUCCESS, payload: response.data })
    typeof response.data?.data == 'object' &&
      Object.keys(response.data?.data).length == 0 &&
      toast.info('We Cannot Find Result for Provided information', { toastId: 'success' })
  } catch (error: any) {
    toast.info(error?.response?.data?.error || error.message, { toastId: body.state })
    dispatch({ type: SEARCH.ERROR, payload: error })
  }
}

export const SearchReset = () => ({
  type: SEARCH.RESET,
})
