import { FC } from 'react'
import { ResultInterfaces } from './interfaces'
import InnerPage from '../InnerPage'
import ResultSection from './ResultSection'
import { connect } from 'react-redux'
import { Search } from '../../store/actions/SearchAction'
import { CombineInterface } from '../../store/reducers/interfaces'
import { ResultBody, ResultRemoveBody, SearchBody } from '../../store/actions/interfaces'
import { ResultList, ResultRemoveAction } from '../../store/actions/ResultActions'
import './styles.scss'

const Result: FC<ResultInterfaces> = ({
  searchReducer: { searchStatus, searchData },
  searchAction,
  resultListAction,
  resultReducer: { resultListStatus, resultListData, resultRemoveStatus },
  resultRemoveAction,
}) => {
  return (
    <>
      <InnerPage>
        <ResultSection
          resultListAction={resultListAction}
          searchStatus={searchStatus}
          searchAction={searchAction}
          requestId={searchData?.data.request_id}
          resultListData={resultListData}
          resultRemoveAction={resultRemoveAction}
          resultRemoveStatus={resultRemoveStatus}
          resultListStatus={resultListStatus}
        />
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ searchReducer, resultReducer }: CombineInterface) => ({
  searchReducer,
  resultReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  searchAction: (body: SearchBody) => dispatch(Search(body)),
  resultListAction: (body: ResultBody) => dispatch(ResultList(body)),
  resultRemoveAction: (body: ResultRemoveBody) => dispatch(ResultRemoveAction(body)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Result)
