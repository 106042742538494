import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { useEffect } from 'react'

import GoogleTagManager from './components/GTM/GoogleTagManager'
import GlobalRoute from './components/GlobalRoute/index'
import { getLogo } from './store/actions/LogoAction'
import Spinner from './core-ui/Spinner'
import { router } from './routes'

import 'react-toastify/dist/ReactToastify.css'
import './App.scss'

function App() {
  const { contactStatus } = useSelector((state: any) => state.contactReducer)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLogo() as any)
  }, [])
  return (
    <>
      <ToastContainer pauseOnFocusLoss={false} pauseOnHover={false} theme='dark' limit={3} />
      <GoogleTagManager />
      {contactStatus == 'pending' && (
        <div className='default-loading bg-black bg-opacity-25'>
          <Spinner />
        </div>
      )}
      <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_CAPTCHA_SITE_KEY || ''}>
        <BrowserRouter>
          <Routes>
            {router.map(({ path, element, isPrivate }, index) => (
              <Route
                key={index}
                path={path}
                element={isPrivate ? <GlobalRoute>{element}</GlobalRoute> : element}
              />
            ))}
          </Routes>
        </BrowserRouter>
      </GoogleReCaptchaProvider>
    </>
  )
}

export default App
