import React, { FC } from 'react'
import './styles.scss'
import { Elements } from '@stripe/react-stripe-js'
import Spinner from '../../core-ui/Spinner'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import { PaymentInterface } from './interfaces'
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '') // Replace with your actual publishable key

const PaymentPlan: FC<PaymentInterface> = ({ stepState, getSubscription, subscribeStatus }) => {
  return (
    <>
      {/* <div className='container'>
        <div className='payment-section'>
          <h1>Payment Method</h1>
        </div>
        {stripePromise ? (
          <Elements
            stripe={stripePromise}
            options={
              {
                // clientSecret: clientSecret,
              }
            }
          >
            <CheckoutForm
              subscribeStatus={subscribeStatus}
              stepState={stepState}
              getSubscription={getSubscription}
            />
          </Elements>
        ) : (
          <div className='stripe-custom-loader'>
            <Spinner />
          </div>
        )}
      </div> */}
    </>
  )
}

export default PaymentPlan
