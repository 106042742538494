/* eslint-disable camelcase */
import { FC, useEffect, useState } from 'react'

import { ProfileNoPendingInterface } from '../../ProfileFields/interfaces'
import RecordBoxes from './RecordBoxes'
import EditProfile from './EditProfile'
import { useDispatch, useSelector } from 'react-redux'
import { getSingleSubProfile, getSubProfiles } from '../../../../store/actions/ProfileAction'
import RecordsTable from '../../../Results/RecordsTable'
import NoResultData from './NoResultData'
import state from '../../../../assets/state'
import ProfileSelect from '../../ProfileSetting/ProfileTopSection/ProfileSelect'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'
import { flatMap } from 'lodash'
export const dateFormatter = (date: string) => {
  const data = date.split('-')
  return [data[1], data[2], data[0]].join('/')
}
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const ProfileNoPending: FC<ProfileNoPendingInterface> = ({
  profileResultNoPendingData,
  profileInfoNoPendingData,
  profileResultNoPendingStatus,
}) => {
  const [userDropdown, setUserDropdown] = useState<any>([])
  const [noData, setNoData] = useState<boolean | null>(true)

  // const [isEditClicked, setIsEditClicked] = useState(false)
  const [isActive, setIsActive] = useState<number[]>([])
  // const [userDetails, setUserDetails] = useState([])

  const navigate = useNavigate()

  const { subProfileData } = useSelector((state: any) => state.profileReducer)
  let webLogos: any[] = []
  const data = localStorage.getItem('webLogos')
  if (data !== 'undefined') {
    // console.log(data)
    webLogos = JSON.parse(data as string)
  }

  // const dispatch = useDispatch()

  const handleClickActive = (date: number) => {
    setIsActive((prev: number[]) => {
      const update = [...prev]
      const index = update.findIndex((item) => item == date)
      if (index >= 0) {
        update.splice(index, 1)
      } else {
        update.push(date)
      }
      return update
    })
  }

  const totalRecords: any = () => {
    const arr = profileResultNoPendingData?.data?.result
    if (arr) {
      return arr
        .map((item: any) => item.data)
        .flat()
        .flatMap((item: any) => Object.values(item))
        .flatMap((item: any) => Object.values(item?.details || {}))
        .flat()?.length
    }

    return 0
  }

  const removedRecords: any = () => {
    const arr = profileResultNoPendingData?.data?.result
    if (arr) {
      return arr
        .map((item: any) => item.data)
        .flat()
        .flatMap((item: any) => Object.values(item))
        .flatMap((item: any) => Object.values(item?.details || {}))
        .flat()
        .filter((item: any) => item?.removalStatus == 'completed')?.length
    }

    return 0
  }

  const pendingRecords: any = () => {
    const arr = profileResultNoPendingData?.data?.result
    if (arr) {
      return arr
        .map((item: any) => item.data)
        .flat()
        .flatMap((item: any) => Object.values(item))
        .flatMap((item: any) => Object.values(item?.details))
        .flat()
        .filter((item: any) => item?.removalStatus == 'pending')?.length
    }

    return 0
  }

  const nextSearchIn = () => profileResultNoPendingData?.data?.nextSearchIn

  // const formattedDateGenerator = (date: string) => {
  //   const splitArray = date.split('-')
  //   const month = monthNames[parseInt(splitArray[1]) - 1]
  //   const formattedDate = `${month} ${splitArray[2]}, ${parseInt(splitArray[0])}`
  //   return formattedDate
  // }

  const dateFinder = (item: any) => {
    let date
    const firstInstance: any = Object.values(item)[0]
    if (firstInstance) {
      date = firstInstance?.updated_at.slice(0, 7)
    }
    return date
  }

  const dateHeadingFormat = (date: string) => {
    const splitArray = date.split('-')
    const month = monthNames[parseInt(splitArray[1]) - 1]
    return `${month} ${parseInt(splitArray[0])}`
  }

  // const handleWebName = (website: string) => {
  //   return images.includes(website.split('.')[1]) ? website.split('.')[1] : '192'
  // }

  // useEffect(() => {
  //   if (profileInfoNoPendingData?.data.firstName) {
  //     setUserDetails(profileInfoNoPendingData?.data)
  //   }
  // }, [profileInfoNoPendingData?.data])

  useEffect(() => {
    // dispatch(getSubProfiles() as any)
    // if (subProfileData?.data?.length >= 0) {
    //   setUserDetails(subProfileData?.data)
    // }

    if (profileInfoNoPendingData?.data && subProfileData?.data?.length >= 0) {
      setUserDropdown([profileInfoNoPendingData.data, ...subProfileData.data])
    }
  }, [subProfileData?.data?.length, profileInfoNoPendingData?.data])

  // this is for check if some records found or not, to conditionally render screens

  useEffect(() => {
    if (profileResultNoPendingData?.data?.result) {
      const arr = profileResultNoPendingData?.data?.result
      const record = totalRecords()
      if (arr) setNoData(!record)
      else setNoData(null)
    } else {
      setNoData(null)
    }
  }, [profileResultNoPendingData?.data?.result?.length])

  const navigator = () => navigate('/profile')

  return (
    <>
      {/* {isEditClicked && (
        <EditProfile
          setIsEditClicked={setIsEditClicked}
          setUserDetails={setUserDetails}
          isEditClicked={isEditClicked}
          userDetails={userDetails}
        />
      )} */}

      {profileInfoNoPendingData?.data?.firstName?.length ? (
        <div className='container'>
          <div className='profile-status-part'>
            <div className='profile-info-section'>
              <div className='d-flex justify-content-between'>
                <h1>Search Results</h1>

                {/* <ProfileSelect
                profileInfoNoPendingData={profileInfoNoPendingData}
                isDropDown={true}
              /> */}
                {/* <button onClick={() => setIsEditClicked(true)}>Edit</button> */}
              </div>
              <p>To add or update your information, go to profile settings.</p>
              {/* 
            <div className='info'>
              <h1 className='text-capitalize'>
                {profileInfoNoPendingData?.data.firstName[0].value || 'James'}{' '}
                {profileInfoNoPendingData?.data.lastName[0].value || 'Smith'}
              </h1>
              <div className='profile-data'>
                <div>
                  <p>Age:</p>
                  <span>{profileInfoNoPendingData?.data.age}</span>
                </div>
                <div>
                  <p>Location:</p>
                  <span>
                    {profileInfoNoPendingData?.data.city[0].value},{' '}
                    {profileInfoNoPendingData?.data.state[0].value}
                  </span>
                </div>
                <div>
                  <p>Mobile:</p>
                  <span>{profileInfoNoPendingData?.data?.phoneNumber[0].value}</span>
                </div>
                <div>
                  <p>Email:</p>
                  <span>{profileInfoNoPendingData?.data.email[0].value}</span>
                </div>
                <div>
                  <p>Date of Birth:</p>
                  <span>
                    {profileInfoNoPendingData?.data.birthDate.split('-').reverse().join('/')}
                  </span>
                </div>
              </div>
            </div> */}
              <div className='profile info'>
                {profileInfoNoPendingData && (
                  <div className='profile-info-box-new'>
                    <div>
                      <label>First Name:</label>
                      <div className='row-data'>
                        {profileInfoNoPendingData?.data?.firstName?.map(
                          (item, index) => item.value && <p key={index}>{item.value}</p>,
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Middle Name:</label>

                      <div className='row-data'>
                        {profileInfoNoPendingData?.data?.middleName?.map(
                          (item, index) => item.value && <p key={index}>{item.value}</p>,
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Last Name:</label>
                      <div className='row-data'>
                        {profileInfoNoPendingData?.data?.lastName.map(
                          (item, index) => item.value && <p key={index}>{item.value}</p>,
                        )}
                      </div>
                    </div>

                    <div>
                      <label>City:</label>
                      <div className='row-data'>
                        {profileInfoNoPendingData?.data?.city.map(
                          (item, index) => item.value && <p key={index}>{item.value}</p>,
                        )}
                      </div>
                    </div>
                    <div>
                      <label>State:</label>
                      <div className='row-data'>
                        {profileInfoNoPendingData?.data?.state.map(
                          (item, index) => item.value && <p key={index}>{item.value}</p>,
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Phone Number:</label>
                      <div className='row-data'>
                        {profileInfoNoPendingData?.data?.phoneNumber.map(
                          (item, index) => item.value && <p key={index}>{item.value}</p>,
                        )}
                      </div>
                    </div>

                    <div>
                      <label>Email Address:</label>
                      <div className='row-data'>
                        {profileInfoNoPendingData?.data?.email.map(
                          (item, index) => item.value && <p key={index}>{item.value}</p>,
                        )}
                      </div>
                    </div>
                    <div>
                      <label>Date of birth:</label>
                      <p className='bg-transparent' style={{ color: '#333333' }}>
                        {/* {profileInfoNoPendingData?.data.birthDate
                        .toString()
                        .split('-')
                        .reverse()
                        .join('/') + ' '} */}
                        {dateFormatter(profileInfoNoPendingData?.data.birthDate)}&nbsp; (
                        {profileInfoNoPendingData?.data?.age})
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {noData == null || noData ? (
              <NoResultData isNull={noData} nextSearch={nextSearchIn()} />
            ) : (
              <div className='records-found'>
                <div className='record-boxes-container'>
                  <RecordBoxes
                    status={'Found'}
                    bgColor={'#CAE5FF'}
                    textColor={'#185C92'}
                    total={totalRecords() || '0'}
                  />
                  <RecordBoxes
                    status={'Removed'}
                    bgColor={'#DFFFE4'}
                    textColor={'#227B30'}
                    total={removedRecords() || '0'}
                  />
                  <RecordBoxes
                    status={'Pending'}
                    bgColor={'#F1F5F9'}
                    textColor={'#3F4040'}
                    total={totalRecords()-removedRecords() || '0'}
                  />
                </div>
                <div className='profile-info-section previous-result'>
                  {profileResultNoPendingData?.data?.result?.length !== 1 &&
                    profileResultNoPendingStatus !== 'pending' && (
                      <>
                        <div className='previous-result-search'>
                          <h1>Previous Results</h1>
                          <div className='next-search'>
                            <h2>Next Search in</h2>
                            <h3>{nextSearchIn()} Days</h3>
                          </div>
                        </div>
                      </>
                    )}
                  {profileResultNoPendingData?.data?.result.map((item: any, index: any) => (
                    <div
                      key={index}
                      className='result-custom-section'
                      style={{
                        border:
                          profileResultNoPendingData?.data?.result?.length == 1
                            ? '1px solid #EAECF0'
                            : '',
                      }}
                    >
                      {profileResultNoPendingData?.data?.result?.length !== 1 &&
                        profileResultNoPendingStatus !== 'pending' && (
                          <div className='asked-box'>
                            <div className=''>
                              <>
                                <div
                                  className='available available-pending'
                                  onClick={() => handleClickActive(index)}
                                >
                                  <div className='available-info'>
                                    <div className='title-section'>
                                      <p>{dateHeadingFormat(item.searchedDate)}</p>
                                      {!isActive.includes(index) ? (
                                        <img
                                          className='vector'
                                          src={
                                            require('../../../../assets/images/subscribe/plus.svg')
                                              .default
                                          }
                                        />
                                      ) : (
                                        <img
                                          className='vector'
                                          src={
                                            require('../../../../assets/images/subscribe/minus.svg')
                                              .default
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        )}
                      <div
                        style={{
                          display:
                            profileResultNoPendingData?.data?.result?.length == 1 ? '' : 'grid',
                          gridTemplateRows: isActive.includes(index) ? '1fr' : '0fr',
                          transition: 'all 0.5s linear',
                        }}
                      >
                        <div
                          className='container search-result-parent result-section rounded-3 overflow-hidden'
                          style={{
                            margin: 0,
                            padding: 0,
                            border: isActive.includes(index) ? '1px solid #EAECF0' : '',
                          }}
                        >
                          {Object.keys(item.data).length ? (
                            <div
                              className='result-table result-custom-table result-custom-table result-custom-section'
                              style={{ margin: 0 }}
                            >
                              <div className='top-box'>
                                <p>Website</p>
                                <p>URL</p>
                                <p></p>
                              </div>
                              <div className='sticky-section'>
                                <div className='data-section'>
                                  {Object.values(item.data)?.map(
                                    ({ details, website }: any, idx: any) => (
                                      <RecordsTable
                                        key={idx}
                                        details={details}
                                        website={website}
                                        setIsActive={setIsActive}
                                        isActive={isActive}
                                        webLogos={webLogos}
                                        index={index}
                                        updated_at={item.searchedDate}
                                      />
                                    ),
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <NoResultData
                              isNull={noData}
                              nextSearch={nextSearchIn()}
                              isInMonth={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        navigator()
      )}
    </>
  )
}

export default ProfileNoPending

{
  /* <div className='pending-content' key={index}>
{Object.entries(details).map(([date, items]: any, index) => (
  <div key={index}>
    {items?.map(
      (
        {
          age,
          address,
          state,
          city,
          firstName,
          lastName,
          screenshot,
        }: any,
        index: number,
      ) => (
        <div className='under-box' key={index}>
          <div className='result-section'>
            <div className='result-table'>
              <>
                <div className='local-box'>
                  <div className='check-state'>
                    <div className='info-state'>
                      {/* <img
                  width={'50%'}
                  className='vector'
                  src={require(`../../../../assets/images/result/website/${handleWebName(
                    website,
                  )}.${
                    handleWebName(website) == '411' ||
                    handleWebName(website) == 'absolutepeoplesearch'
                      ? 'png'
                      : 'svg'
                  }`)}
                /> */
}
//     <img
//       width={'70%'}
//       className='vector'
//       src={
//         screenshot ||
//         require('../../../../assets/images/result/website/192.svg')
//           .default
//       }
//       alt={'not found'}
//     />
//     <p className='text-overflow-ellipses'>
//       {website?.split('//')?.[1]}
//     </p>
//   </div>
// </div>

//                         <div className='all-section'>
//                     <div className='middle-box'>
//                       <span className='name-state text-capitalize'>
//                         {firstName || ''} {lastName || ''}
//                       </span>
//                       <p>
//                         <span>Age:</span>
//                         <span>{age || ''}</span>
//                       </p>
//                       <p>
//                         <span>Location:</span>
//                         <span>
//                           {' '}
//                           {address || ''}
//                           {address && ','} {state || ''}
//                           {state && ','} {city || ''}
//                         </span>
//                       </p>
//                     </div>
//                   </div>

//                   <div className='status-button'>
//                     <div className='mobile-view'>
//                       <span>Removal Status</span>
//                     </div>
//                     <div
//                       className='removalStatus'
//                       style={{
//                         background:
//                           status == 'completed'
//                             ? '#28C771'
//                             : '#F2FAFD',
//                         color:
//                           status == 'completed'
//                             ? '#ffffff'
//                             : '#0A4C70',
//                       }}
//                     >
//                       {status == 'completed'
//                         ? 'removed'
//                         : 'pending'}
//                     </div>
//                   </div>

//                   <p className='responsive d-flex justify-content-between w-100 px-0'>
//                     <span className='mobile-view'>
//                       <span>Removal Requested On</span>
//                     </span>
//                     {formattedDateGenerator(date)}
//                   </p>
//                 </div>
//               </>
//             </div>
//           </div>
//         </div>
//       ),
//     )}
//   </div>
// ))}
// </div>
