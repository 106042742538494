import React from 'react'

export default function Blob() {
  return (
    <svg
      width='1118'
      height='330'
      viewBox='0 0 1118 330'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1051.16 16.41C910.235 50.9309 809.968 72.0437 699.196 90.3548C571.236 111.568 480.245 120.273 373.156 121.574C257.474 122.875 166.756 115.97 25.972 94.8576C-30.7779 86.3524 -96.6679 75.4458 -236.769 51.3312C-375.78 27.4167 -461.723 14.709 -514.926 10.4064C-609.464 2.60168 -705.502 8.80545 -795.538 28.5174L-812.318 32.1196L-812.591 181.01L-813 330H152.568H1118V164.9C1118 74.045 1117.86 -0.0999451 1117.73 9.15527e-05C1117.45 0.100159 1087.58 7.50464 1051.16 16.41Z'
        fill='#5D576B'
      />
    </svg>
  )
}
