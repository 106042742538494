import { useState } from 'react'

const GeneratePromoCode = ({ handleSubmit }: any) => {
  const [promoCode, setPromoCode] = useState(location.pathname.split('/')[3])
  const [copied, setCopied] = useState(false)
  const handleCopyPromoCode = () => {
    console.log('Attempting to copy promo code:', promoCode)
    navigator.clipboard
      .writeText(promoCode)
      .then(() => {
        console.log('Promo code copied successfully:', promoCode)
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 3000)
      })
      .catch((error) => {
        console.error('Failed to copy promo code:', error)
      })
  }

  return (
    <div>
      <h1 className='promo-code-heading'>
        Here is your <br />
        <span>Unique Promo Code</span> for your company
      </h1>
      <div className='copy-promo-container'>
        <input className='promoCodeStyle' type='text' value={promoCode} readOnly />

        <button onClick={handleCopyPromoCode}>{copied ? 'Copied' : 'Copy'}</button>
      </div>
      <div className='copy-promo-container'>
        <p className='discount-price'>Use this code for the discounted price.</p>
        <span>
          <a href='#'>Signup Now</a>
        </span>
      </div>
    </div>
  )
}

export default GeneratePromoCode
