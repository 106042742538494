/* eslint-disable react/no-unescaped-entities */
export default function NoResultData({
  isNull,
  nextSearch,
  isInMonth,
}: {
  isNull: boolean | null
  nextSearch?: number | undefined
  isInMonth?: boolean
}) {
  return (
    <div className='recordsNotFound'>
      <img
        src={
          isNull == null
            ? require('../../../../assets/images/profile/Frame18594.svg').default
            : require('../../../../assets/images/result/noResult.svg').default
        }
      />
      <div>
        <h1>No Results {isNull == null ? 'Yet' : ''}</h1>
        {!isInMonth && (
          <p>
            {isNull == null ? (
              <>
                We are using your profile information to search over 80 data broker sites.
                <br />
                The results will soon be fetched and displayed on this screen.
                <br />
                Check back in 5-10 minutes to see your initial results and track the progress.
              </>
            ) : (
              <>
                Great News! Looks like we couldn't find anything just yet.
                <br />
                We will search again in <strong>{nextSearch}</strong> days.
              </>
            )}
          </p>
        )}
      </div>
    </div>
  )
}
