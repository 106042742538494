import { FC } from 'react'
import ProfileTopSection from '../ProfileTopSection'
import { ProfileViewSectionInterface } from './interface'
import state from '../../../../assets/state'
import Spinner from '../../../../core-ui/Spinner'
import { dateFormatter } from '../../SearchResults/ProfileNoPending'

const NewProfileView: FC<ProfileViewSectionInterface> = ({
  setEditProfileState,
  profileInfoNoPendingData,
  editProfileState,
  profileInfoNoPendingStatus,
}) => {
  const handleClick = () => {
    setEditProfileState(true)
  }

  return (
    <>
      <div className='profile'>
        <ProfileTopSection
          title='Profile'
          description="Updating your details won't trigger a new search, but they will be used in the upcoming searches"
          type='view'
          buttonTitle='Edit'
          onClick={handleClick}
          editProfileState={editProfileState}
          profileInfoNoPendingData={profileInfoNoPendingData}
        />
        {profileInfoNoPendingStatus === 'pending' ? (
          <div className='big-spinner' style={{ marginTop: -200 }}>
            <Spinner />
          </div>
        ) : (
          profileInfoNoPendingData && (
            <div className='profile-info-box-new'>
              <div>
                <label>First Name:</label>
                <div className='row-data'>
                  {profileInfoNoPendingData?.data?.firstName?.map(
                    (item, index) => item.value && <p key={index}>{item?.value}</p>,
                  )}
                </div>
              </div>
              <div>
                <label>Middle Name:</label>

                <div className='row-data'>
                  {profileInfoNoPendingData?.data?.middleName?.map(
                    (item, index) => item.value && <p key={index}>{item?.value}</p>,
                  )}
                </div>
              </div>
              <div>
                <label>Last Name:</label>
                <div className='row-data'>
                  {profileInfoNoPendingData?.data?.lastName?.map(
                    (item, index) => item.value && <p key={index}>{item?.value}</p>,
                  )}
                </div>
              </div>

              <div>
                <label>City:</label>
                <div className='row-data'>
                  {profileInfoNoPendingData?.data?.city?.map(
                    (item, index) => item.value && <p key={index}>{item?.value}</p>,
                  )}
                </div>
              </div>
              <div>
                <label>State:</label>
                <div className='row-data'>
                  {profileInfoNoPendingData?.data?.state.map(
                    (item, index) => item.value && <p key={index}>{item?.value}</p>,
                  )}
                </div>
              </div>
              <div>
                <label>Phone Number:</label>
                <div className='row-data'>
                  {profileInfoNoPendingData?.data?.phoneNumber?.map(
                    (item, index) => item.value && <p key={index}>{item?.value}</p>,
                  )}
                </div>
              </div>

              <div>
                <label>Email Address:</label>
                <div className='row-data'>
                  {profileInfoNoPendingData?.data?.email?.map(
                    (item, index) => item.value && <p key={index}>{item?.value}</p>,
                  )}
                </div>
              </div>
              <div>
                <label>Date of birth:</label>
                <p className='bg-transparent' style={{ color: '#333333' }}>
                  {/* {profileInfoNoPendingData?.data?.birthDate
                    ?.toString()
                    ?.split('-')
                    ?.reverse()
                    ?.join('/') + ' '} */}
                  {dateFormatter(profileInfoNoPendingData?.data?.birthDate)}&nbsp; (
                  {profileInfoNoPendingData?.data?.age})
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </>
  )
}

export default NewProfileView
