import { Button } from 'antd'
import InnerPage from '../../InnerPage'
import './styles.scss'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
export default function ChooseOption() {
  const [chooseOption, setChooseOption] = useState(0)
  const navigate = useNavigate()

  const handleContinue = () => {
    if (chooseOption === 1) {
      console.log('1')
      navigate('/business/promo-code')
    } else {
      navigate('/business/landing-page-generate')
    }
  }
  return (
    <div>
      <InnerPage>
        <div className='d-flex justify-content-center'>
          <div className='choose-main'>
            <div className='text'>
              <h1>Welcome to Hackfree</h1>
              <div>
                <p>
                  Would you like to offer Hackfree web-scrubbing & Privacy protection for your
                  employees?
                </p>
                <p>
                  It&apos;s Simple to add. Just choose which way you would like to offer Hackfree to
                  your employees.
                </p>
              </div>
            </div>
            <div className='choose-option'>
              <h2>Choose your Option</h2>
              <div
                className='option'
                style={{ borderColor: chooseOption == 1 ? '#2ba0db' : '#d0d5dd' }}
                onClick={() => setChooseOption(1)}
              >
                <div className='custom-radio'>
                  {chooseOption == 1 && <div className='inner-radio' />}
                </div>
                <p>Generate a unique promo code for your employees.</p>
              </div>
              <div
                className='option'
                style={{ borderColor: chooseOption == 2 ? '#2ba0db' : '#d0d5dd' }}
                onClick={() => setChooseOption(2)}
              >
                <div className='custom-radio'>
                  {chooseOption == 2 && <div className='inner-radio' />}
                </div>
                <p>Generate a custom landing page for your employees.</p>
              </div>
            </div>
            <p>
              You can also speak with our sales team for a custom package for your team.{' '}
              <span>Contact sales</span>
            </p>
            <p>
              Base Price for Hackfree is <span> $50/employee/year</span>{' '}
            </p>
            <div className='continue'>
              <Button onClick={handleContinue}>Continue</Button>
            </div>
          </div>
        </div>
      </InnerPage>
    </div>
  )
}
