import React, { FC, useState, useEffect } from 'react'
import { Input, Select, Button, DatePicker, DatePickerProps } from 'antd'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { Auth } from 'aws-amplify'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/en'

import { phoneFormatter } from '../../ContactUs/ContactSection'
import { ProfileFieldsSectionInterface } from './interfaces'
import { useSearchParams } from 'react-router-dom'
import Spinner from '../../../core-ui/Spinner'
import state from '../../../assets/state'
import {
  cityRegex,
  emailRegex,
  lastNameRegex,
  nameRegex,
  usPhoneRegex,
  middleNameRegex,
} from '../../../utils/regex'

import './styles.scss'

const ProfileFieldsSection: FC<ProfileFieldsSectionInterface> = ({
  profileAction,
  profileStatus,
}) => {
  const [searchParams] = useSearchParams()
  const { Option } = Select
  const [buttonClick, setButtonClick] = useState(false)
  const [selectState, setSelectState] = useState(searchParams.get('state') || '')
  const [dateOfBirth, setDateOfBirth] = useState<string | string[]>('')
  const [formData, setFormData] = useState({
    firstName: searchParams.get('firstName') || '',
    lastName: searchParams.get('lastName') || '',
    middleName: searchParams.get('middleName') || '',
    city: searchParams.get('city') || '',
    email: '',
    phoneNumber: '',
  })
  useEffect(() => {
    ;(async () => {
      const user = await Auth.currentUserInfo()

      const data = user.attributes['email']
      setFormData({ ...formData, email: data })
    })()
    dayjs.extend(customParseFormat)
  }, [])
  const handleChange = (
    event_: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>,
    stateName: string,
  ) => {
    let { value } = event_.target
    if (stateName == 'phoneNumber') {
      value = phoneFormatter(value)
    }
    setFormData((previousState) => ({
      ...previousState,
      [stateName]: value.trim(),
    }))
  }
  const handleChangeSelect = (event: string) => {
    setSelectState(event)
  }

  const onChangeDate: DatePickerProps['onChange'] = (date, dateString) => {
    setDateOfBirth(dateString)
  }

  const handleClick = () => {
    setButtonClick(true)
    console.log('button click')
    if (
      cityRegex.test(formData.city) &&
      nameRegex.test(formData.firstName) &&
      middleNameRegex.test(formData.middleName) &&
      lastNameRegex.test(formData.lastName) &&
      usPhoneRegex.test(formData.phoneNumber) &&
      dateOfBirth &&
      selectState
    ) {
      const body = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        middleName: formData.middleName,
        // eslint-disable-next-line camelcase
        birth_date: dateOfBirth,
        city: formData.city,
        state: selectState,
        phoneNumber:
          formData.phoneNumber == ''
            ? ''
            : phoneNumberFormatter(phoneDeFormatter(formData.phoneNumber)),
      }
      profileAction(body)
    }
  }

  const phoneNumberFormatter = (phone: string) => {
    return '(' + phone.slice(0, 3) + ')' + ' ' + phone.slice(3, 6) + '-' + phone.slice(6, 10)
  }
  const phoneDeFormatter = (phone: string) => {
    return phone
      .split('')
      .filter((item) => !isNaN(parseInt(item)))
      .join('')
  }
  const disableFutureDates = (current: Dayjs) => {
    // Disable dates after today
    return current && current.isAfter(dayjs().endOf('day'))
  }
  return (
    <>
      <div className='container'>
        <div className='profile-section'>
          <div className='title-box'>
            <h1>
              <span>One Last Step!</span>
            </h1>
            <h1 className='profile-title'>Create Hackfree Profile</h1>
            <p>
              Provided details will be used to search for you on
              <br /> data broker sites and remove the found info monthly.
            </p>
          </div>
          <div className='fields-section'>
            <div className='fields'>
              <div className='colum'>
                <div className='child-box'>
                  <label>First name</label>
                  <Input
                    defaultValue={searchParams.get('firstName') || ''}
                    className='input-box'
                    onChange={(e) => handleChange(e, 'firstName')}
                    placeholder='First name'
                  />
                  {buttonClick && (!formData.firstName || !nameRegex.test(formData.firstName)) ? (
                    <p className='error-message message-active'>*Please enter a valid first name</p>
                  ) : (
                    <p className='error-message message-disable'>
                      *Please enter a valid first name
                    </p>
                  )}
                </div>
                <div className='child-box'>
                  <label>Middle name</label>
                  <Input
                    defaultValue={searchParams.get('middleName') || ''}
                    className='input-box'
                    onChange={(e) => handleChange(e, 'middleName')}
                    placeholder='Middle name'
                  />
                  {buttonClick && !middleNameRegex.test(formData.middleName.trim()) ? (
                    <p className='error-message message-active'>
                      *Please enter a valid middle name
                    </p>
                  ) : (
                    <p className='error-message message-disable'>
                      *Please enter a valid middle name
                    </p>
                  )}
                </div>
              </div>
              <div className='colum'>
                <div className='child-box'>
                  <label>Last name</label>
                  <Input
                    defaultValue={searchParams.get('lastName') || ''}
                    className='input-box'
                    onChange={(e) => handleChange(e, 'lastName')}
                    placeholder='Last name'
                  />
                  {buttonClick &&
                  (!formData.lastName || !lastNameRegex.test(formData.lastName.trim())) ? (
                    <p className='error-message message-active'>*Please enter a valid last name</p>
                  ) : (
                    <p className='error-message message-disable'>*Please enter a valid last name</p>
                  )}
                </div>

                <div className='child-box'>
                  <label>Date of Birth</label>
                  <DatePicker
                    onChange={onChangeDate}
                    placeholder='Date of birth'
                    disabledDate={disableFutureDates}
                    format={'MM-DD-YYYY'}
                    // minDate={dayjs(moment('2005-01-01', 'YYYY-MM-DD').toDate())}
                  />

                  {!dateOfBirth && buttonClick ? (
                    <p className='error-message message-active'>
                      *Please enter a valid date of birth
                    </p>
                  ) : (
                    <p className='error-message message-disable'>
                      *Please enter a valid date of birth
                    </p>
                  )}
                </div>
              </div>

              <div className='colum'>
                <div className='child-box'>
                  <label>Email</label>
                  <Input
                    value={formData.email}
                    disabled
                    className='input-box'
                    onChange={(e) => handleChange(e, 'email')}
                    placeholder='Enter your email'
                    style={{ color: '#777777', fontWeight: 600 }}
                  />
                  {buttonClick && (!formData.email || !emailRegex.test(formData.email.trim())) ? (
                    <p className='error-message message-active'>*Please enter a valid email</p>
                  ) : (
                    <p className='error-message message-disable'>*Please enter a valid email</p>
                  )}
                </div>
                <div className='child-box'>
                  <label>Phone</label>
                  <Input
                    value={formData.phoneNumber}
                    className='input-box'
                    onChange={(e) => handleChange(e, 'phoneNumber')}
                    placeholder='Phone number'
                    type='text'
                  />
                  {buttonClick && !usPhoneRegex.test(formData.phoneNumber.trim()) ? (
                    <p className='error-message message-active'>
                      *Please enter valid format e.g (559) 123 45678
                    </p>
                  ) : (
                    <p className='error-message message-disable'>
                      *Please enter valid format e.g (559) 123 45678
                    </p>
                  )}
                </div>
              </div>

              <div className='colum'>
                <div className='child-box'>
                  <label>City</label>
                  <Input
                    defaultValue={searchParams.get('city') || ''}
                    className='input-box'
                    onChange={(e) => handleChange(e, 'city')}
                    placeholder='City'
                  />
                  {buttonClick && (!formData.city || !cityRegex.test(formData.city.trim())) ? (
                    <p className='error-message message-active'>*Please enter a valid city</p>
                  ) : (
                    <p className='error-message message-disable'>*Please enter a valid city</p>
                  )}
                </div>

                <div className='child-box'>
                  <label>State</label>
                  <Select
                    placeholder='state'
                    suffixIcon={
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='21'
                        viewBox='0 0 20 21'
                        fill='none'
                      >
                        <path
                          d='M5 7.53809L10 12.5381L15 7.53809'
                          stroke='#667085'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </svg>
                    }
                    onChange={(e) => handleChangeSelect(e)}
                  >
                    {state.map(({ name }) => (
                      <>
                        <Option value={name}>{name}</Option>
                      </>
                    ))}
                  </Select>
                  {buttonClick && !selectState ? (
                    <p className='error-message message-active'>*Please enter a valid state</p>
                  ) : (
                    <p className='error-message message-disable'>*Please enter a valid state</p>
                  )}
                </div>
              </div>
            </div>
            <div className='button-box'>
              <Button onClick={handleClick}>
                {profileStatus === 'pending' ? <Spinner /> : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProfileFieldsSection
