import React, { FC } from 'react'
import './styles.scss'
import InnerPage from '../InnerPage'
import TermsSection from './TermsOfUse'

const Terms: FC = () => {
  return (
    <>
      <InnerPage>
        <TermsSection />
      </InnerPage>
    </>
  )
}

export default Terms
