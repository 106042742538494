/* eslint-disable no-empty */
import { FC, useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { HeaderInterface } from '../interfaces'
import { connect, useSelector } from 'react-redux'
import { Button, Popover } from 'antd'
import { Auth } from 'aws-amplify'

import { NewProfileInfoNoPendingAction } from '../../../store/actions/ProfileAction'
import { CombineInterface } from '../../../store/reducers/interfaces'
import { signUpReset } from '../../../store/actions/AuthActions'
import queryString from 'query-string'
import MobileHeader from './MobileHeader'

import '../styles.scss'
import Logo from '../../../utils/Logo'

let opened = false

const Header: FC<HeaderInterface> = ({
  signUpResetAction,
  profileReducer: { profileInfoNoPendingData },
  NewProfileInfoNoPendingAction,
  isLandingPage,
  isSlugLanding,
}) => {
  const firstName = localStorage.getItem('firstName') || 'X'
  const lastName = localStorage.getItem('lastName') || 'X'
  const login = localStorage.getItem('login')

  const [mobileMenuState, setMobileMenuState] = useState(false)
  const [sizeSate, setSizeState] = useState(false)
  const parsed = queryString.parse(location.search)
  const [email, setEmail] = useState('')

  const navigate = useNavigate()

  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setSizeState(true)
    } else {
      setSizeState(false)
    }
  }

  const handleClick = () => {
    if (window.location.pathname === '/sign-in') {
      if (!parsed.firstName) {
        // navigate('/sign-up')
      } else {
        navigate(
          `/sign-up?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`,
        )
      }
      signUpResetAction()
    } else {
      if (!parsed.firstName) {
        navigate('/sign-in')
      } else {
        navigate(
          `/sign-in?firstName=${parsed.firstName}&lastName=${parsed.lastName}&city=${parsed.city}&state=${parsed.state}`,
        )
      }
    }
  }

  const handleLogOutClick = () => {
    localStorage.clear()
    navigate('/')
  }

  useEffect(() => {
    ;(async () => {
      try {
        const user = await Auth.currentUserInfo()
        const data = user?.attributes['email']
        setEmail(data)
      } catch (error) {}
    })()
  }, [])

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const user = await Auth.currentUserInfo()
        if (user && !profileInfoNoPendingData?.data.firstName[0].value) {
          NewProfileInfoNoPendingAction()
        }
      } catch (error) {}
    })()
  }, [firstName])

  const content = (
    <div className='popup-box'>
      <div className='info-box'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='32'
          height='33'
          viewBox='0 0 32 33'
          fill='none'
        >
          <path
            d='M7.08774 25.9559C7.89884 24.045 9.79258 22.7047 11.9993 22.7047H19.9993C22.2061 22.7047 24.0998 24.045 24.911 25.9559M21.3327 12.7047C21.3327 15.6502 18.9449 18.038 15.9993 18.038C13.0538 18.038 10.666 15.6502 10.666 12.7047C10.666 9.75919 13.0538 7.37138 15.9993 7.37138C18.9449 7.37138 21.3327 9.75919 21.3327 12.7047ZM29.3327 16.038C29.3327 23.4018 23.3631 29.3714 15.9993 29.3714C8.63555 29.3714 2.66602 23.4018 2.66602 16.038C2.66602 8.67425 8.63555 2.70471 15.9993 2.70471C23.3631 2.70471 29.3327 8.67425 29.3327 16.038Z'
            stroke='#333333'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <div className='name-section'>
          <h6>
            {profileInfoNoPendingData?.data?.firstName?.[0]?.value || firstName}{' '}
            {profileInfoNoPendingData?.data?.lastName?.[0]?.value || lastName}
          </h6>
          <p>{email}</p>
        </div>
      </div>
      <div onClick={() => navigate('/profile-view')} className='info-box'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='22'
          height='22'
          viewBox='0 0 22 22'
          fill='none'
        >
          <path
            d='M18.108 19.2C18.108 17.944 18.108 17.316 17.953 16.805C17.604 15.6544 16.7036 14.754 15.553 14.405C15.042 14.25 14.414 14.25 13.158 14.25H8.65801C7.402 14.25 6.774 14.25 6.26298 14.405C5.11242 14.754 4.21204 15.6544 3.86302 16.805C3.70801 17.316 3.70801 17.944 3.70801 19.2M14.958 7.05C14.958 9.28675 13.1448 11.1 10.908 11.1C8.67125 11.1 6.85801 9.28675 6.85801 7.05C6.85801 4.81325 8.67125 3 10.908 3C13.1448 3 14.958 4.81325 14.958 7.05Z'
            stroke='#475467'
            strokeWidth='2.025'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <div className='name-section'>
          <p>Profile Settings</p>
        </div>
      </div>
      {/* <div onClick={() => navigate('/business/plan')} className='info-box'>
        <img src={require('../../../assets/images/icon/business.png')} alt='' height={'70%'} />
        <div className='name-section'>
          <p>Business</p>
        </div>
      </div> */}
      <div className='info-box'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='22'
          height='23'
          viewBox='0 0 22 23'
          fill='none'
        >
          <path
            d='M14.5076 16.1001L19.0076 11.6001M19.0076 11.6001L14.5076 7.10012M19.0076 11.6001H8.20762M8.20762 3.50012H7.12762C5.61548 3.50012 4.85941 3.50012 4.28184 3.7944C3.77381 4.05326 3.36076 4.46631 3.1019 4.97435C2.80762 5.55191 2.80762 6.30798 2.80762 7.82012V15.3801C2.80762 16.8923 2.80762 17.6483 3.1019 18.2259C3.36076 18.7339 3.77381 19.147 4.28184 19.4058C4.8594 19.7001 5.61548 19.7001 7.12762 19.7001H8.20762'
            stroke='#475467'
            strokeWidth='2.025'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
        <div onClick={handleLogOutClick} className='name-section'>
          <p>Log out</p>
        </div>
      </div>
    </div>
  )

  // const openedState = false
  const handleClickMobileMenuIcon = () => {
    if (!mobileMenuState) {
      setMobileMenuState(true)
    } else {
      setMobileMenuState(false)
    }
  }

  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node | null
      if (wrapperRef.current && !wrapperRef.current.contains(target) && !opened) {
        setMobileMenuState(false)
      }

      opened = false
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [mobileMenuState, wrapperRef])
  const payment = localStorage.getItem('payment')
  return (
    <>
      <div className='header'>
        <div className='header-part' style={{ borderBottom: isLandingPage ? 'none' : 'none' }}>
          <div className='container'>
            <div className='header-section'>
              <Logo
                onClick={() => navigate('/')}
                // src={require('../../../assets/images/logo/Logo.png')}
                style={{ marginInline: isSlugLanding ? 'auto' : '' }}
              />
              {!isLandingPage && !isSlugLanding && (
                <div className='account-section'>
                  {window.location.pathname !== '/sign-up' &&
                  window.location.pathname !== '/sign-in' &&
                  window.location.pathname !== '/create-password' ? (
                    <>
                      {sizeSate ? (
                        <div ref={wrapperRef}>
                          <svg
                            onClick={handleClickMobileMenuIcon}
                            xmlns='http://www.w3.org/2000/svg'
                            width='24'
                            height='25'
                            viewBox='0 0 24 25'
                            fill='none'
                          >
                            <path
                              d='M3 12.6406H21M3 6.64062H21M3 18.6406H21'
                              stroke='#333333'
                              strokeWidth='2'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                            />
                          </svg>
                        </div>
                      ) : (
                        <ul>
                          <li
                            className={window.location.pathname === '/' ? 'active' : ''}
                            onClick={() => navigate('/')}
                          >
                            Home
                          </li>
                          <li
                            className={window.location.pathname === '/contact' ? 'active' : ''}
                            onClick={() => navigate('/contact')}
                          >
                            Contact
                          </li>
                          {/* {!payment ? ( */}
                          {/* <li
                            className={window.location.pathname === '/subscribe' ? 'active' : ''}
                            onClick={() => navigate('/subscribe')}
                          >
                            Pricing
                          </li> */}
                          {/* ) : (
                        ''
                      )} */}
                          {profileInfoNoPendingData?.data?.firstName && login && (
                            <li
                              className={
                                window.location.pathname === '/search-result' ? 'active' : ''
                              }
                              onClick={() => navigate('/search-results')}
                            >
                              Search Results
                            </li>
                          )}
                          {login ? (
                            profileInfoNoPendingData?.data?.firstName && (
                              <>
                                <Popover placement='bottom' content={content}>
                                  <li>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width='32'
                                      height='33'
                                      viewBox='0 0 32 33'
                                      fill='none'
                                    >
                                      <path
                                        d='M7.08774 25.9559C7.89884 24.045 9.79258 22.7047 11.9993 22.7047H19.9993C22.2061 22.7047 24.0998 24.045 24.911 25.9559M21.3327 12.7047C21.3327 15.6502 18.9449 18.038 15.9993 18.038C13.0538 18.038 10.666 15.6502 10.666 12.7047C10.666 9.75919 13.0538 7.37138 15.9993 7.37138C18.9449 7.37138 21.3327 9.75919 21.3327 12.7047ZM29.3327 16.038C29.3327 23.4018 23.3631 29.3714 15.9993 29.3714C8.63555 29.3714 2.66602 23.4018 2.66602 16.038C2.66602 8.67425 8.63555 2.70471 15.9993 2.70471C23.3631 2.70471 29.3327 8.67425 29.3327 16.038Z'
                                        stroke='#333333'
                                        strokeWidth='2'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                    </svg>
                                  </li>
                                </Popover>
                              </>
                            )
                          ) : (
                            <>
                              <Button onClick={() => navigate('/sign-in')}>
                                Login{' '}
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                >
                                  <path
                                    d='M2.99219 20C5.32798 17.5226 8.4992 16 11.9922 16C15.4852 16 18.6564 17.5226 20.9922 20M16.4922 7.5C16.4922 9.98528 14.4775 12 11.9922 12C9.50691 12 7.49219 9.98528 7.49219 7.5C7.49219 5.01472 9.50691 3 11.9922 3C14.4775 3 16.4922 5.01472 16.4922 7.5Z'
                                    stroke='white'
                                    strokeWidth='2'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </svg>
                              </Button>
                            </>
                          )}
                        </ul>
                      )}
                    </>
                  ) : (
                    // <>
                    //   <p>
                    //     {/*eslint-disable*/}
                    //     {window.location.pathname === '/sign-in' ? "Don't" : 'Already'} have an
                    //     account?
                    //   </p>
                    //   <p>
                    //     <span onClick={handleClick}>
                    //       {window.location.pathname === '/sign-in' ? 'Sign up' : 'Sign in'}
                    //     </span>
                    //   </p>
                    // </>
                    <>
                      <p>
                        {/*eslint-disable*/}
                        {window.location.pathname === '/sign-in' ? '' : 'Already have an account?'}
                      </p>
                      <p>
                        <span onClick={handleClick}>
                          {window.location.pathname === '/sign-in' ? '' : 'Sign in'}
                        </span>
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='container'>
          <div
            style={{
              overflow: 'hidden',
              height: mobileMenuState && sizeSate ? 190 : 0,
              transition: '.3s all ease',
            }}
          >
            <MobileHeader
              firstName={profileInfoNoPendingData?.data?.firstName?.[0]?.value || ''}
              lastName={profileInfoNoPendingData?.data?.lastName?.[0]?.value || ''}
              login={login}
              content={content}
              handleLogOutClick={handleLogOutClick}
              payment={payment || ''}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateFromProps = ({ profileReducer }: CombineInterface) => ({
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  signUpResetAction: () => dispatch(signUpReset()),
  NewProfileInfoNoPendingAction: () => dispatch(NewProfileInfoNoPendingAction()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Header)
