import { SEARCH } from '../constants'
import { DispatchPayloadType } from './index'

import { SearchReducersInterface } from './interfaces'

const initialState: SearchReducersInterface = {
  searchStatus: '',
  searchData: null,
}

const searchReducer = (
  state = initialState,
  { type, payload, error }: DispatchPayloadType,
): SearchReducersInterface => {
  switch (type) {
    case SEARCH.PENDING: {
      return {
        ...state,
        searchStatus: 'pending',
      } as SearchReducersInterface
    }
    case SEARCH.SUCCESS: {
      return {
        ...state,
        searchStatus: 'success',
        searchData: payload,
      } as SearchReducersInterface
    }
    case SEARCH.ERROR: {
      return {
        ...state,
        searchStatus: 'error',
        searchData: error,
      } as SearchReducersInterface
    }
    case SEARCH.RESET: {
      return {
        ...state,
        searchStatus: '',
      } as SearchReducersInterface
    }
    default:
      return state
  }
}

export default searchReducer
