import React, { FC, useEffect, useState } from 'react'
import './styles.scss'
import { Button } from 'antd'
import { SubscribeSectionInterface } from './interfaces'
import queryString from 'query-string'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router'

const SubscribeSection: FC<SubscribeSectionInterface> = ({
  setPaymentState,
  getSubscription,
  setStepState,
  stepState,
}) => {
  const [questionState, setQuestionState] = useState([
    {
      text: 'Is there a free trial available?',
      buttonText: 'Sorry, There is no trial available.',
      id: '1',
      imgPlus: require('../../assets/images/subscribe/plus.svg'),
      imgMin: require('../../assets/images/subscribe/minus.svg'),
      active: 'plus',
    },
    {
      text: 'Can I change my plan later?',
      buttonText: 'Sorry, There is no trial available.',
      id: '2',
      imgPlus: require('../../assets/images/subscribe/plus.svg'),
      imgMin: require('../../assets/images/subscribe/minus.svg'),
      active: 'plus',
    },
    {
      text: 'What is your cancellation policy?',
      buttonText: 'Sorry, There is no trial available.',
      id: '3',
      imgPlus: require('../../assets/images/subscribe/plus.svg'),
      imgMin: require('../../assets/images/subscribe/minus.svg'),
      active: 'plus',
    },
    {
      text: 'How does billing work?',
      buttonText: 'Sorry, There is no trial available.',
      id: '4',
      imgPlus: require('../../assets/images/subscribe/plus.svg'),
      imgMin: require('../../assets/images/subscribe/minus.svg'),
      active: 'plus',
    },
    {
      text: 'How do I change my account email?',
      buttonText: 'Sorry, There is no trial available.',
      id: '5',
      imgPlus: require('../../assets/images/subscribe/plus.svg'),
      imgMin: require('../../assets/images/subscribe/minus.svg'),
      active: 'plus',
    },
  ])
  const [auth, setAuth] = useState(false)

  const navigate = useNavigate()

  const parsed = queryString.parse(location.search)

  const handleClick = (id: string, type: string) => {
    const newData = [...questionState]
    for (const [idx, result] of questionState.entries()) {
      if (result.id === id) {
        newData[idx].active = type
      }
    }
    setQuestionState(newData)
  }

  const handleClickGetStarted = () => {
    // if (!auth) navigate('/sign-in')
    // else
    setPaymentState(true)
  }

  const handleStepClick = (type: string) => {
    setStepState(type)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const authentication = await Auth.currentSession()
        setAuth(!!authentication)
      } catch (error) {
        setAuth(false)
      }
    })()
  }, [])

  return (
    <>
      <div className='container'>
        <div className='subscribe-section'>
          <h1>Find the Perfect Plan for You</h1>
          <p>Simple transparent pricing.</p>
          <div className='payment-box'>
            <div
              className={stepState === 'monthly' ? 'item item-active' : 'item'}
              onClick={() => handleStepClick('monthly')}
            >
              <h1>
                $129<span style={{ color: '#5a5b5b' }}>/month</span>
              </h1>
              <p>
                Lorem ipsum dolor sit amet consectetur. Quam tellus semper eget proin a elementum
                consequat.
              </p>
            </div>
            <div
              className={stepState === 'yearly' ? 'item item-active' : 'item'}
              onClick={() => handleStepClick('yearly')}
            >
              <div className='payment-button subsribe-button'>
                <h1>
                  $1200<span style={{ color: '#5a5b5b' }}>/year</span>
                </h1>
                <div className=''>Save 22.47%</div>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur.Quam tellus semper eget proin a elementum
                consequat.
              </p>
            </div>
          </div>
          <Button onClick={handleClickGetStarted}>Subscribe</Button>
        </div>
      </div>
    </>
  )
}

export default SubscribeSection
