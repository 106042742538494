import { checkSubscription, getSubscription } from '../../store/actions/SubscribeActions'
import { FC, useState, useEffect } from 'react'
import InnerPage from '../InnerPage'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { CombineInterface } from '../../store/reducers/interfaces'
import SubscribeSection from './SubscribeSection'
import PaymentPlan from './PaymentPlan'
import { SubscribeBody } from '../../store/actions/interfaces'
import { SubscribeInterface } from './interfaces'
import queryString from 'query-string'

import './styles.scss'

const Subscribe: FC<SubscribeInterface> = ({
  subscribeReducer: { subscribeStatus, getSubscribeData },
  getSubscription,
  profileReducer: { profileInfoNoPendingData },
  checkSubscription,
}) => {
  const parsed = queryString.parse(location.search)
  const navigate = useNavigate()
  const [paymentState, setPaymentState] = useState(false)
  const [stepState, setStepState] = useState('yearly')

  useEffect(() => {
    if (getSubscribeData?.data && Object.values(getSubscribeData?.data).length) {
      if (!parsed?.firstName) {
        navigate('/profile')
      } else {
        navigate(
          `/profile?firstName=${parsed?.firstName}&lastName=${parsed?.lastName}&middleName=${parsed?.middleName}&city=${parsed?.city}&state=${parsed?.state}`,
        )
      }
    }
  }, [parsed, profileInfoNoPendingData?.data])

  // useEffect(() => {
  //   if (subscribeStatus === 'success') {
  //     if (!parsed?.firstName) {
  //       navigate('/profile')
  //     } else {
  //       navigate(
  //         `/profile?firstName=${parsed?.firstName}&lastName=${parsed?.lastName}&city=${parsed?.city}&state=${parsed?.state}`,
  //       )
  //     }
  //   }
  // }, [subscribeStatus, parsed])

  useEffect(() => {
    // checkSubscription()
    navigate('/profile')
  }, [])

  return (
    <>
      <InnerPage>
        <>
          {!paymentState ? (
            <>
              <SubscribeSection
                setStepState={setStepState}
                setPaymentState={setPaymentState}
                getSubscription={getSubscription}
                stepState={stepState}
              />
            </>
          ) : (
            <>
              <PaymentPlan
                subscribeStatus={subscribeStatus}
                stepState={stepState}
                getSubscription={getSubscription}
              />
            </>
          )}
        </>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ subscribeReducer, profileReducer }: CombineInterface) => ({
  subscribeReducer,
  profileReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  getSubscription: (body: SubscribeBody) => dispatch(getSubscription(body)),
  checkSubscription: () => dispatch(checkSubscription()),
})

export default connect(mapStateFromProps, mapDispatchToProps)(Subscribe)
