import { BUSINESS } from '../constants'
import { DispatchPayloadType } from '.'
import { BusinessReducersInterface } from './interfaces'

const initialState: BusinessReducersInterface = {
  businessStatus: '',
  businessData: {},
  error: '',
  success: '',
}

export const BusinessReducer = (
  state = initialState,
  { type, error, payload }: DispatchPayloadType,
) => {
  switch (type) {
    case BUSINESS.PENDING: {
      return {
        ...state,
        businessStatus: 'pending',
        businessData: {},
        error: '',
        success: '',
      } as BusinessReducersInterface
    }
    case BUSINESS.SUCCESS: {
      return {
        ...state,
        businessStatus: 'success',
        businessData: payload?.data,
        success: payload?.data.message,
      } as BusinessReducersInterface
    }
    case BUSINESS.ERROR: {
      return {
        ...state,
        businessStatus: 'error',
        error: error?.response?.data?.error,
      } as BusinessReducersInterface
    }
    case BUSINESS.RESET: {
      return {
        ...state,
        businessData: [],
        businessStatus: '',
        error: '',
        success: '',
      } as BusinessReducersInterface
    }
    default: {
      return {
        ...state,
      } as BusinessReducersInterface
    }
  }
}
