import React, { FC, useEffect } from 'react'
import { ChangePasswordInterfaces } from './interfaces'
import { useNavigate } from 'react-router-dom'
import { Input } from 'antd'
import PasswordPopover from '../Login/SignUp/PasswordPopover'
import { validatePassword } from '../../utils/regex'

const ChangePasswordFields: FC<ChangePasswordInterfaces> = ({
  handleChangePassword,
  formDataChangePassword,
  passwordState,
  handleFocusValidation,
  buttonClick,
  confirmPasswordData,
  confirmPasswordStatus,
}) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (confirmPasswordStatus === 'success') {
      navigate('/sign-in')
    }
  }, [confirmPasswordStatus])
  return (
    <>
      <style>
        {`
      input{
        margin-bottom:0 !important;
      }
      `}
      </style>
      <label>Code*</label>
      <Input
        onChange={(e) => handleChangePassword(e, 'code')}
        type='number'
        placeholder='Enter your code'
      />
      {(!formDataChangePassword.code || !/[0-9]{6}/.test(formDataChangePassword.code)) &&
      buttonClick ? (
        <p className='error-message message-active'>*Please enter a valid code</p>
      ) : (
        <p className='error-message message-disable'>*Please enter a valid code</p>
      )}
      <label>Password*</label>
      <Input
        onChange={(e) => handleChangePassword(e, 'newPassword')}
        type='password'
        onFocus={() =>
          handleFocusValidation('focus', 'newPassword', formDataChangePassword.newPassword)
        }
        onBlur={() =>
          handleFocusValidation('blur', 'newPassword', formDataChangePassword.newPassword)
        }
        placeholder='Enter your password'
      />
      {(!formDataChangePassword.newPassword ||
        !validatePassword(formDataChangePassword.newPassword)) &&
      buttonClick ? (
        <p className='error-message message-active'>*Please enter a valid password</p>
      ) : (
        <p className='error-message message-disable'>*Please enter a valid password</p>
      )}
      <div className='popover_box'>
        {passwordState && <PasswordPopover password={formDataChangePassword.newPassword} />}
      </div>
      <label>Confirm Password*</label>
      <Input
        onChange={(e) => handleChangePassword(e, 'confirmPassword')}
        type='password'
        placeholder='Confirm password'
      />
      {!formDataChangePassword.confirmPassword && buttonClick ? (
        <p className='error-message message-active'>*Please enter a valid password</p>
      ) : (
        <p className='error-message message-disable'>*Please enter a valid password</p>
      )}
      {formDataChangePassword.confirmPassword !== formDataChangePassword.newPassword &&
        buttonClick && <p className='error-message message-active'>*Password did not match</p>}
      {confirmPasswordData && confirmPasswordStatus !== 'pending' && buttonClick ? (
        <p className='error-message message-active'>{confirmPasswordData}</p>
      ) : (
        <p className='error-message message-disable'>{confirmPasswordData}</p>
      )}
    </>
  )
}

export default ChangePasswordFields
