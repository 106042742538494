import React, { FC, useState } from 'react'
import { Input, Button } from 'antd'
import { SignInFieldsInterface } from './interfaces'
import Spinner from '../../../core-ui/Spinner'
import PasswordPopover from './PasswordPopover'
import { validatePassword } from '../../../utils/regex'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

let passwordCheckOpened = false

const SignInFields: FC<SignInFieldsInterface> = ({
  handleClickButton,
  formData,
  setFormData,
  signUpStatus,
  errorMessage,
  setErrorBackState,
  errorBackState,
}) => {
  const [passwordState, setPasswordState] = useState(false)
  const [changeType, setChangeType] = useState(false)

  const handleChange = (event: any, fieldKey: any) => {
    setFormData((e) => {
      const result = [...e]
      const key = result.findIndex(({ key }) => key === fieldKey)
      result[key].value =
        fieldKey === 'email'
          ? event.target.value.toLowerCase().replace(/\s/g, '')
          : event.target.value.replace(/\s/g, '')
      if (fieldKey === 'password' && passwordState && validatePassword(event.target.value)) {
        setPasswordState(false)
      } else if (
        fieldKey === 'password' &&
        !validatePassword(event.target.value) &&
        !passwordState &&
        passwordCheckOpened
      ) {
        setPasswordState(true)
      }
      result[key].errorVisible = false
      return result
    })
    setErrorBackState(true)
  }

  const handleFocusValidation = (val: string, key: string, value: string) => {
    if (key === 'password') {
      if (val === 'focus') {
        passwordCheckOpened = true
        if (!validatePassword(value)) {
          setPasswordState(true)
        }
      } else {
        setPasswordState(false)
      }
    }
  }

  return (
    <>
      <h1>Create an account</h1>
      <div className='pb-3'>
        {formData.map(({ label, key, placeholder, errorText, value, errorVisible }) => (
          <div className='form-field-item' key={key}>
            <label>{label}</label>
            <Input
              type={key === 'password' ? (changeType ? 'text' : 'password') : 'text'}
              value={value}
              onChange={(e) => handleChange(e, key)}
              placeholder={placeholder}
              onFocus={() => handleFocusValidation('focus', key, value)}
              onBlur={() => handleFocusValidation('blur', key, value)}
              addonAfter={
                key == 'password' &&
                (changeType ? (
                  <EyeInvisibleOutlined onClick={() => setChangeType(false)} />
                ) : (
                  <EyeOutlined onClick={() => setChangeType(true)} />
                ))
              }
            />
            {/* {label == 'Password*' && <p className='password-info'>Must be at least 8 characters</p>} */}
            {errorVisible ? (
              <p className={`error-message ${key == 'password' && 'message-active'}`}>
                {errorText}
              </p>
            ) : (
              ''
            )}
            {key === 'password' && passwordState && <PasswordPopover password={value} />}
          </div>
        ))}
      </div>
      {errorMessage && signUpStatus !== 'pending' && !errorBackState ? (
        <p className='error-message message-active'>{errorMessage}</p>
      ) : (
        ''
      )}
      <Button onClick={handleClickButton}>
        {signUpStatus === 'pending' ? <Spinner /> : 'Get started'}
      </Button>
      <p className='text-center availability-color'>Currently only available to US residents</p>
    </>
  )
}

export default SignInFields
