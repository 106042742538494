import React, { useState, useEffect, FC } from 'react'
import { FooterInterface } from '../interfaces'
import Logo from '../../../utils/FooterLogo'

const Footer: FC<FooterInterface> = ({ isLandingPage }) => {
  const [sizeSate, setSizeState] = useState(false)
  const handleResize = () => {
    if (window.innerWidth <= 992) {
      setSizeState(true)
    } else {
      setSizeState(false)
    }
  }
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className='footer'>
      <div className='container'>
        {sizeSate ? (
          <div className='footer-section'>
            {/* <img alt='' src={require('../../../assets/images/footer/logo-footer.svg').default} /> */}
            <Logo />
            <div className='footer-content'>
              <div className='footer-items'>
                <a href='/contact'>Contact Us</a>
                <a href='/privacy-policy'>Privacy Policy</a>
                <a href='/terms'>Terms of Use</a>
              </div>
            </div>
            <p>© 2024 Hackfree</p>
          </div>
        ) : (
          <div className='footer-content'>
            {/* <img alt='' src={require('../../../assets/images/footer/logo-footer.svg').default} /> */}
            <Logo />
            {!isLandingPage && (
              <div className='footer-items'>
                <a href='/contact'>Contact Us</a>
                <a href='/privacy-policy'>Privacy Policy</a>
                <a href='/terms'>Terms of Use</a>
              </div>
            )}
            {isLandingPage && (
              <div className='footer-items'>
                <a href='/contact'>Contact Us</a>
                <a href='/about'>About</a>
              </div>
            )}
            {!isLandingPage && <p>© 2024 Hackfree</p>}
            {isLandingPage && (
              <div className='d-flex gap-4'>
                <img
                  src={require('../../../assets/images/landingPage/Twitter.svg').default}
                  alt='icon issue'
                />
                <img
                  src={require('../../../assets/images/landingPage/Facebook.svg').default}
                  alt='icon issue'
                />
                <img
                  src={require('../../../assets/images/landingPage/Linkedin.svg').default}
                  alt='icon issue'
                />
                <img
                  src={require('../../../assets/images/landingPage/Instagram.svg').default}
                  alt='icon issue'
                />
              </div>
            )}
          </div>
        )}
      </div>
      {isLandingPage && (
        <>
          <div className='divider' />
          <div className='container'>
            <div className='footer-content'>
              <span style={{ color: '#E2F6FC' }}>
                Copyright © 2024 Hackfree. All Rights Reserved.
              </span>
              <div className='footer-items' style={{ color: '#E2F6FC' }}>
                <a href='/terms'>Terms of Service</a>
                <a href='/privacy-policy'>Privacy Policy</a>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Footer
