import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router'
import Blob from '../../utils/Blob'
import PuzzleBlob from '../../utils/PuzzleBlob'

export default function ContactAction() {
  const navigate = useNavigate()
  return (
    <div className='contact-action-main'>
      <div className='text-box'>
        <h1>Let’s see if Hackfree is your perfect fit</h1>
        <Button onClick={() => navigate('/contact')}>Contact Us</Button>
      </div>
      <div className='blob'>
        <Blob />
      </div>
      <div className='puzzle-blob'>
        <PuzzleBlob />
      </div>
    </div>
  )
}
