import React from 'react'
import { WhoNeedStepInterface } from './interfaces'

export default function WhoNeedStep({ heading, image, paragraph }: WhoNeedStepInterface) {
  return (
    <div className='who-need-step-main'>
      <div className='image-container'>
        <img src={image} alt='something went wrong' />
      </div>
      <h1>{heading}</h1>
      <p>{paragraph}</p>
    </div>
  )
}
