import { useState } from 'react';

export default function HeroSection() {

  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };
  
  return (
    <div className='hero-container'>
      <div className='hero-box'>
        <div className='left'>
        {!isImageLoaded && <div className='placeholder'></div>}
          <img
            src={require('../../assets/images/newHome/hero.png')}
            alt='no internet'
            className='w-full '
            onLoad={handleImageLoad}
            style={{ display: isImageLoaded ? 'block' : 'none' }}
          />
        </div>
        <div className='right'>
          <h1>
            Take control of your <br />
            online identity
          </h1>
          <p>
            The internet often tells our story without our say-so. Search engines decide what pops
            up about us, and sometimes it’s stuff we’d rather keep private – like financial details,
            court records, our age, and even our home address.
          </p>
          <p>
            This kind of exposure is a goldmine for hackers. They can piece together synthetic
            identities, leading to ID theft, digital breaches, and deepfakes that trick us into
            making bad decisions.
          </p>
        </div>
      </div>
    </div>
  )
}
