import React, { FC } from 'react'
import './styles.scss'
import InnerPage from '../InnerPage'
import { connect } from 'react-redux'
import { CombineInterface } from '../../store/reducers/interfaces'
import ContactSection from './ContactSection'
import { ContactAction } from '../../store/actions/ContactAction'
import { ContactBody } from '../../store/actions/interfaces'
import { ContactUsInterface } from './interfaces'

const ContactUs: FC<ContactUsInterface> = ({
  contactAction,
  contactReducer: { contactStatus, contactData },
}) => {
  return (
    <>
      <InnerPage>
        <ContactSection
          errorText={contactData?.error || ''}
          contactAction={contactAction}
          contactStatus={contactStatus}
        />
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ contactReducer }: CombineInterface) => ({
  contactReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  contactAction: (body: ContactBody) => dispatch(ContactAction(body)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(ContactUs)
