import ContactUs from '../ContactUs'

export default function Test() {
  function grandParent() {
    console.log('grandParent: ', this)

    function parent() {
      console.log('parent: ', this.name)

      function me() {
        console.log('me: ', this.name)
      }
      function sibling() {
        console.log('sibling: ', this)
      }
      return { me }
    }

    return {
      parent,
    }
  }

  const grand = {
    name: 'this is for grand Parents',
  }

  const newFn = grandParent.bind(grand)
  console.log(grandParent().parent().me.call(grand))
  return <input type='tel' id='phone' name='phone' pattern='[0-9]{3}-[0-9]{3}-[0-9]{4}' required />
}
