import { RESULT_LIST, RESULT_REMOVE } from '../constants'
import { DispatchPayloadType } from './index'

import { ResultReducersInterface } from './interfaces'

const initialState: ResultReducersInterface = {
  resultListStatus: '',
  resultListData: null,

  resultRemoveStatus: '',
  resultRemoveData: null,
}

const resultReducer = (
  state = initialState,
  { type, payload, error }: DispatchPayloadType,
): ResultReducersInterface => {
  switch (type) {
    case RESULT_LIST.PENDING: {
      return {
        ...state,
        resultListStatus: 'pending',
      } as ResultReducersInterface
    }
    case RESULT_LIST.SUCCESS: {
      return {
        ...state,
        resultListStatus: 'success',
        resultListData: payload,
      } as ResultReducersInterface
    }
    case RESULT_LIST.ERROR: {
      return {
        ...state,
        resultListStatus: 'error',
        resultListData: error,
      } as ResultReducersInterface
    }
    case RESULT_LIST.RESET: {
      return {
        ...state,
        resultListStatus: '',
      } as ResultReducersInterface
    }

    case RESULT_REMOVE.PENDING: {
      return {
        ...state,
        resultRemoveStatus: 'pending',
      } as ResultReducersInterface
    }
    case RESULT_REMOVE.SUCCESS: {
      return {
        ...state,
        resultRemoveStatus: 'success',
        resultRemoveData: payload,
      } as ResultReducersInterface
    }
    case RESULT_REMOVE.ERROR: {
      return {
        ...state,
        resultRemoveStatus: 'error',
        resultRemoveData: error,
      } as ResultReducersInterface
    }
    case RESULT_REMOVE.RESET: {
      return {
        ...state,
        resultRemoveStatus: '',
      } as ResultReducersInterface
    }
    default:
      return state
  }
}

export default resultReducer
