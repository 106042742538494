import React from 'react'
import GeneratePromoCode from './GeneratePromoCode'
import './styles.scss'
import InnerPage from '../../InnerPage'
export default function index() {
  return (
    <InnerPage>
      <div className='d-flex justify-content-center'>
        <div className='width-promo-code form-container'>
          <>
            <GeneratePromoCode />
          </>
        </div>
      </div>
    </InnerPage>
  )
}
