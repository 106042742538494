import React, { useState, FC, useEffect } from 'react'
import './styles.scss'
import { connect, useSelector } from 'react-redux'
import { CombineInterface } from '../../../store/reducers/interfaces'
import { SignUpInterfaces } from './interfaces'
import { ResendSignUp, signUp, signUpReset, verifyPhone } from '../../../store/actions/AuthActions'
import { SignUpInterface, VerifyPhoneInterface } from '../../../store/actions/interfaces'
import { emailRegex, passwordRegex } from '../../../utils/regex'
import SignInFields from './SignUpFields'
import InnerPage from '../../InnerPage'
import CheckSection from './CheckSection'
import { useNavigate } from 'react-router'

const SignUp: FC<SignUpInterfaces> = ({
  authReducer: { signUpData, verifyData, signUpStatus, verifyStatus },
  signUpAction,
  verifyPhoneAction,
  signUpResetAction,
  resendSignUp,
}) => {
  const { profileInfoNoPendingData } = useSelector((state: any) => state.profileReducer)
  const navigate = useNavigate()
  const [checkBox, setCheckBox] = useState(false)
  const [errorBackState, setErrorBackState] = useState(false)
  const [formData, setFormData] = useState([
    {
      name: 'Email*',
      placeholder: 'Enter your email',
      key: 'email',
      type: 'email',
      value: '',
      label: 'Email*',
      errorText: '*Please enter a valid email address',
      errorVisible: false,
    },
    {
      name: 'Password*',
      placeholder: 'Create a password',
      key: 'password',
      value: '',
      type: 'password',
      label: 'Password*',
      errorText: '*Please enter a valid password',
      errorVisible: false,
    },
  ])

  const [buttonClick, setButtonClick] = useState(false)

  const formValidate = () => {
    const newData = [...formData]
    let isValid = true

    for (const [idx, { key, value }] of formData.entries()) {
      if (key === 'email' && !emailRegex.test(value.trim())) {
        newData[idx].errorVisible = true
        isValid = false
      }
      if (key === 'password' && !passwordRegex.test(value.trim())) {
        newData[idx].errorVisible = true
        isValid = false
      }
    }

    setFormData(newData)

    return isValid
  }
  const formDataFunc = () => {
    const result: Record<string, string> = {}
    for (const { key, value } of formData) {
      result[key] = value
    }
    return result
  }
  const handleClickButton = () => {
    setButtonClick(true)
    formValidate()
    if (formValidate()) {
      setErrorBackState(false)
      const { password, email } = formDataFunc()
      const body = {
        email,
        password,
      }
      signUpAction(body)
    }
  }

  useEffect(() => {
    if (signUpStatus === 'success') {
      setCheckBox(true)
    } else {
      setCheckBox(false)
    }
  }, [checkBox, signUpStatus])
  useEffect(() => {
    if (profileInfoNoPendingData?.data?.profileId) return navigate('/')
  }, [profileInfoNoPendingData?.data?.profileId])

  return (
    <>
      <InnerPage>
        <div className='sign-up-section'>
          <div className='sign-up-box'>
            <>
              {!checkBox || signUpStatus !== 'success' ? (
                <SignInFields
                  formData={formData}
                  handleClickButton={handleClickButton}
                  setFormData={setFormData}
                  signUpStatus={signUpStatus}
                  errorMessage={signUpData?.message || ''}
                  errorBackState={errorBackState}
                  setErrorBackState={setErrorBackState}
                />
              ) : (
                <CheckSection
                  setCheckBox={setCheckBox}
                  verifyPhoneAction={verifyPhoneAction}
                  email={formData[0].value}
                  password={formData[1].value}
                  signUpResetAction={signUpResetAction}
                  verifyStatus={verifyStatus}
                  errorMessage={verifyData?.message || ''}
                  resendSignUp={resendSignUp}
                />
              )}
            </>
          </div>
          <div> </div>
        </div>
      </InnerPage>
    </>
  )
}

const mapStateFromProps = ({ authReducer }: CombineInterface) => ({
  authReducer,
})

const mapDispatchToProps = (dispatch: (event: any) => void) => ({
  signUpAction: (body: SignUpInterface) => dispatch(signUp(body)),
  verifyPhoneAction: (body: VerifyPhoneInterface) => dispatch(verifyPhone(body)),
  signUpResetAction: () => dispatch(signUpReset()),
  resendSignUp: (username: string) => dispatch(ResendSignUp(username)),
})

export default connect(mapStateFromProps, mapDispatchToProps)(SignUp)
