import { useEffect, useState } from 'react'
import InnerPage from '../../InnerPage'
import './styles.scss'
import PromoCodeForm from './PromoCodeForm'
import { useDispatch, useSelector } from 'react-redux'
import { createThroughPromoCode } from '../../../store/actions/BusinessAction'
import { PromoBody } from '../../../store/actions/interfaces'
import { useNavigate } from 'react-router'

export default function PromoCode() {
  const { businessData } = useSelector((state: any) => state.BusinessReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [promoCode, setPromoCode] = useState(false)
  const [formData, setFormData] = useState<PromoBody>({
    companyName: '',
    websiteUrl: '',
    pocName: '',
    pocEmailAddress: '',
    pocTitle: '',
    pocPhone: '',
    estimateUsers: 0,
  })
  const handleChange = (e: any) => {
    const { name, value } = e.target
    console.log(name, value)
    setFormData({
      ...formData,
      [name]: value,
    })
  }
  const handleSubmit = (event: any) => {
    event.preventDefault()
    dispatch(createThroughPromoCode(formData) as any)
    // setPromoCode(!promoCode)
  }

  useEffect(() => {
    if (businessData?.promoCode) {
      navigate(businessData?.promoCode)
    }
  }, [businessData?.promoCode])
  return (
    <div>
      <InnerPage>
        <div className='promo-code'>
          <div className={`form-container ${promoCode ? 'width-promo-code' : ''}`}>
            <>
              <h1 className=''>Promo Code Generator</h1>
              <p>Fill all details about your Company</p>

              <PromoCodeForm
                formData={formData}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </>
          </div>
        </div>
      </InnerPage>
    </div>
  )
}
